import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateWithNoDatePipe'
})
export class DateWithNoDatePipe implements PipeTransform {
    transform(value: string) {
        const locale = localStorage.getItem('locale') || "en-US";
        var datePipe = new DatePipe(locale);
        try {
            value = datePipe.transform(value, 'yyyy-MM-dd');
            return value;
        }
        catch (exp) {
            return 'delivery_date_not_defined';
        }
    }
}