import { Type } from '@angular/core';

export class DynamicComponentSelectorImport {
  public selector: string;

  public importFunction: Promise<Type<any>>
  public inputs: any;
  public outputs: any;

  public static getAllComponents(): Map<string, Promise<Type<any>>> {
    const result: Map<string, Promise<Type<any>>> = new Map();
    const mediaSliderTwo = DynamicComponentSelectorImport.mediaSliderTwo();
    const galleryOne = DynamicComponentSelectorImport.galleryOne();
    const galleryTwo = DynamicComponentSelectorImport.galleryTwo();
    const singleImage = DynamicComponentSelectorImport.singleImageDynamic();
    const parallaxMedia = DynamicComponentSelectorImport.parallaxMedia();
    const textDynamicComponent = DynamicComponentSelectorImport.text();
    const textFullWidthDynamicComponent = DynamicComponentSelectorImport.textFullWidth();
    const imageTextBoxOverDynamicComponent = DynamicComponentSelectorImport.imageTextBoxOverDynamicComponent();
    const expansionPanelDynamicComponent = DynamicComponentSelectorImport.expansionPanelDynamicComponent();
    const alertMessageDynamicComponent = DynamicComponentSelectorImport.alertMessageDynamicComponent();

    result.set(mediaSliderTwo.selector,mediaSliderTwo.importFunction);
    result.set(galleryOne.selector,galleryOne.importFunction);
    result.set(galleryTwo.selector, galleryTwo.importFunction);
    result.set(parallaxMedia.selector,parallaxMedia.importFunction);
    result.set(imageTextBoxOverDynamicComponent.selector, imageTextBoxOverDynamicComponent.importFunction);
    result.set(textDynamicComponent.selector, textDynamicComponent.importFunction);
    result.set(textFullWidthDynamicComponent.selector, textFullWidthDynamicComponent.importFunction);
    result.set(singleImage.selector, singleImage.importFunction);
    result.set(expansionPanelDynamicComponent.selector, expansionPanelDynamicComponent.importFunction);
    result.set(alertMessageDynamicComponent.selector, alertMessageDynamicComponent.importFunction);

    return result;
  }
  
  public static importAllNecessary(componentCodes: string[]): {} {
    const avaialableComponents = {};
    DynamicComponentSelectorImport.getAllComponents().forEach((value: Promise<Type<any>>, key: string) => {
      for(let code of componentCodes) {
        if(code && code === key) {
          avaialableComponents[code] = value;
        }
      }
    });
    return avaialableComponents;
  }

  public static twoColumnTextDynamic(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-two-column-text-dynamic';
    component.importFunction = import(/* webpackChunkName: 'pdp-two-column-text-dynamic' */
      `./two-column-text-dynamic/two-column-text-dynamic.component`)
      .then(({ TwoColumnTextDynamicComponent }) => TwoColumnTextDynamicComponent);
    component.inputs = {
      items: new Array()
    }
    return component;
  }

  public static oneColumnTextDynamic(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-one-column-text-dynamic';
    component.importFunction = import(/* webpackChunkName: 'pdp-one-column-text-dynamic' */
      `./one-column-text-dynamic/one-column-text-dynamic.component`)
      .then(({ OneColumnTextDynamicComponent }) => OneColumnTextDynamicComponent);
    component.inputs = {
      items: new Array(),
      parentToComponentId: null,
      parentId: null,
      resourceType: null,
      componentType: null
    }
    return component;
  }

  public static singleImageDynamic(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-single-image-dynamic';
    component.importFunction = import(/* webpackChunkName: 'pdp-single-image-dynamic' */
      `./single-image-dynamic/single-image-dynamic.component`)
      .then(({ SingleImageDynamicComponent }) => SingleImageDynamicComponent);
    component.inputs = {
      items: new Array(),
      resourceType: null
    }
    return component;
  }

  public static imageRightTextLeftDynamic(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-image-right-text-left-dynamic';
    component.importFunction = import(/* webpackChunkName: 'pdp-image-right-text-left-dynamic' */
      `./image-right-text-right-shop-dynamic/image-right-text-right-shop-dynamic.component`)
      .then(({ ImageRightTextRightShopDynamicComponent }) => ImageRightTextRightShopDynamicComponent);
    component.inputs = {
      items: new Array(),
      resourceType: null
    }
    return component;
  }

  public static imageLeftTextRightDynamic(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-image-left-text-right-dynamic';
    component.importFunction = import(/* webpackChunkName: 'pdp-image-left-text-right-dynamic' */
      `./image-left-text-right-shop-dynamic/image-left-text-right-shop-dynamic.component`)
      .then(({ ImageLeftTextRightShopDynamicComponent }) => ImageLeftTextRightShopDynamicComponent);
    component.inputs = {
      items: new Array(),
      resourceType: null
    }
    return component;
  }

  /**
 * Products
 */
  // public static productSliderTwo(): DynamicComponentSelectorImport {
  //   const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
  //   component.selector = 'pdp-product-slider-two';
  //   component.importFunction = import(/* webpackChunkName: 'components-product-slider-two' */`./product-slider-two/product-slider-two.component`)
  //     .then(({ ProductSliderTwoComponent }) => ProductSliderTwoComponent);

  //   return component;
  // }

  // public static productSpecialTwo(): DynamicComponentSelectorImport {
  //   const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
  //   component.selector = 'pdp-special-products-two';
  //   component.importFunction = import(/* webpackChunkName: 'components-product-special-two' */`./special-products-two/special-products-two.component`)
  //     .then(({ SpecialProductsTwoComponent }) => SpecialProductsTwoComponent);
  //   component.inputs = { products: new Array() }
  //   return component;
  // }

  /**
   * Media
   */
  public static mediaSliderTwo(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-slider-two';
    component.importFunction = import(/* webpackChunkName: 'components-media-slider-two' */`./slider-two/slider-two.component`)
      .then(({ SliderTwoComponent: SliderTwoComponent }) => SliderTwoComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static galleryTwo(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-gallery-two';
    component.importFunction = import(/* webpackChunkName: 'components-gallery-two' */`./gallery-two/gallery-two.component`)
      .then(({ GalleryTwoComponent: GalleryTwoComponent }) => GalleryTwoComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static galleryOne(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-gallery-one';
    component.importFunction = import(/* webpackChunkName: 'components-gallery-one' */`./gallery-one/gallery-one.component`)
      .then(({ GalleryOneComponent: GalleryOneComponent }) => GalleryOneComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static parallaxMedia(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-parallax-banner-two';
    component.importFunction = import(/* webpackChunkName: 'components-parallax-banner-two' */`./parallax-banner-two/parallax-banner-two.component`)
      .then(({ ParallaxBannerTwoComponent: ParallaxBannerTwoComponent }) => ParallaxBannerTwoComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static text(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-text';
    component.importFunction = import(/* webpackChunkName: 'components-media-slider-two' */`./text/text.component`)
      .then(({ TextComponent: TextComponent }) => TextComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static textFullWidth(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-text-full-width';
    component.importFunction = import(/* webpackChunkName: 'pdp-text-full-width' */`./text-full-width/text-full-width.component`)
      .then(({ TextFullWidthComponent: TextFullWidthComponent }) => TextFullWidthComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  // public static fromTheBlog(): DynamicComponentSelectorImport {
  //   const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
  //   component.selector = 'pdp-from-the-blog';
  //   component.importFunction = import(/* webpackChunkName: 'components-from-the-blog' */`./from-the-blog/from-the-blog.component`)
  //     .then(({ FromTheBlogComponent: fromTheBlogComponent }) => fromTheBlogComponent);
  //   component.inputs = { items: new Array() }
  //   return component;
  // }

  public static imageTextBoxOverDynamicComponent(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-image-text-box-over-dynamic';
    component.importFunction = import(/* webpackChunkName: 'components-image-text-over-box' */`./image-text-box-over-dynamic/image-text-box-over-dynamic.component`)
      .then(({ ImageTextBoxOverDynamicComponent: ImageTextBoxOverDynamicComponent }) => ImageTextBoxOverDynamicComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static expansionPanelDynamicComponent(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-expansion-panel-dynamic';
    component.importFunction = import(/* webpackChunkName: 'pdp-expansion-panel-dynamic' */`./expansion-panel-dynamic/expansion-panel-dynamic.component`)
      .then(({ ExpansionPanelDynamicComponent: ExpansionPanelDynamicComponent }) => ExpansionPanelDynamicComponent);
    component.inputs = { items: new Array() }
    return component;
  }

  public static alertMessageDynamicComponent(): DynamicComponentSelectorImport {
    const component: DynamicComponentSelectorImport = new DynamicComponentSelectorImport();
    component.selector = 'pdp-dynamic-alert-message';
    component.importFunction = import(/* webpackChunkName: 'pdp-dynamic-alert-message' */`./dynamic-alert-message/dynamic-alert-message.component`)
      .then(({ DynamicAlertMessageComponent: DynamicAlertMessageComponent }) => DynamicAlertMessageComponent);
    component.inputs = { items: new Array() }
    return component;
  }
}

