export enum ContentType {
    LINK = 'LINK',
    LINK_EXTERNAL = 'LINK_EXTERNAL',
    MAT_ICON = 'MAT_ICON',
    MAT_ICON_WITH_HOVER = 'MAT_ICON_WITH_HOVER',
    MAT_ICON_WITHOUT_HOVER = 'MAT_ICON_WITHOUT_HOVER',
    IMG_ICON = 'IMG_ICON',
    TEXT = 'TEXT',
    TEXT_WITH_OUTPUT = 'TEXT_WITH_OUTPUT',
    TEXT_WITH_REMOVE_ICON = 'TEXT_WITH_REMOVE_ICON',
    MEDIA = 'MEDIA',
    MAT_INPUT = 'MAT_INPUT',
    MAT_INPUT_TEXT = 'MAT_INPUT_TEXT',
    DIALOG_WITH_ICON = 'DIALOG_WITH_ICON',
    MAT_SELECT = 'MAT_SELECT',
    MAT_SELECT_TWO = 'MAT_SELECT_TWO',
    MAT_SELECT_MO_PROCESS_DETAILS = 'MAT_SELECT_MO_PROCESS_DETAILS',
    MAT_SELECT_MO_PROCESS_FULFILMENT_BREAKDOWN = 'MAT_SELECT_MO_PROCESS_FULFILMENT_BREAKDOWN',
    TEXT_WITH_PREFIX = 'TEXT_WITH_PREFIX',
    PRICE = 'PRICE',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    DATE_TIME_SECONDS = 'DATE_TIME_SECONDS',
    DATE_TIME_MILIS = 'DATE_TIME_MILIS',
    MAT_CHECKBOX = 'MAT_CHECKBOX',
    MAT_CHECKBOX_WITH_FULFILMENT_INVOICE_COG = 'MAT_CHECKBOX_WITH_FULFILMENT_INVOICE_COG',
    MAT_CHECKBOX_WITH_FULFILMENT_COG = 'MAT_CHECKBOX_WITH_FULFILMENT_COG',
    MAT_DELETE_BUTTON = 'MAT_DELETE_BUTTON',
    BUTTON = 'BUTTON',
    MULTI_BUTTON = 'MULTI_BUTTON',
    CUSTOM_FOR_EACH_ITEM = 'CUSTOM_FOR_EACH_ITEM',
 }
