import { Injectable, OnDestroy } from '@angular/core';
import { PlatformHttpService } from '../../common/http/service/platformhttp.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Constants } from '../../common/constants';
import { HttpParams } from '@angular/common/http';
import { filter, takeUntil } from 'rxjs/operators';
import { Menu } from '@pdp/common';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  constructor(public platformHttpService: PlatformHttpService) { }

  // https://dev.to/avatsaev/simple-state-management-in-angular-with-only-services-and-rxjs-41p8
  // https://stackblitz.com/edit/angular-rxjs-store?file=src%2Fapp%2Ftodos-store.service.ts
  // - We set the initial state in BehaviorSubject's constructor
  // - Nobody outside the Store should have access to the BehaviorSubject 
  //   because it has the write rights
  // - Writing to state should be handled by specialized Store methods (ex: addTodo, removeTodo, etc)
  // - Create one BehaviorSubject per store entity, for example if you have TodoGroups
  //   create a new BehaviorSubject for it, as well as the observable$, and getters/setters
  private readonly _horizonalMenu: Subject<Menu> = new BehaviorSubject<Menu>(null);
  private readonly _verticalMenu: Subject<Menu> = new BehaviorSubject<Menu>(null);
  private readonly _footerMenu: Subject<Menu> = new BehaviorSubject<Menu>(null);

  // Expose the observable$ part of the _todos subject (read only stream)
  readonly horizonalMenu$: Observable<Menu> = this._horizonalMenu.asObservable();
  readonly verticalMenu$: Observable<Menu> = this._verticalMenu.asObservable();
  readonly footerMenu$: Observable<Menu> = this._footerMenu.asObservable();

  // the getter will return the last value emitted in _todos subject
  public get getHorizontalMenuItems(): Observable<Menu> {
    return this.horizonalMenu$.pipe(filter(filterNullValue => filterNullValue != null));
  }

  // the getter will return the last value emitted in _todos subject
  public get getVerticalMenuItems(): Observable<Menu> {
    return this.verticalMenu$.pipe(filter(filterNullValue => filterNullValue != null));
  }

  // the getter will return the last value emitted in _todos subject
  public get getFooterMenuItems(): Observable<Menu> {
    return this.footerMenu$.pipe(filter(filterNullValue => filterNullValue != null));
  }

  // assigning a value to this.todos will push it onto the observable 
  // and down to all of its subsribers (ex: this.todos = [])
  protected set setHorizonalMenuItems(val: Menu) {
    this._horizonalMenu.next(val);
  }

  protected removeHorizonal() {
    this.setHorizonalMenuItems = null;
  }

  protected set setVerticalMenuItems(val: Menu) {
    this._verticalMenu.next(val);
  }

  protected removeVertical() {
    this.setVerticalMenuItems = null;
  }

  protected set setFooterMenuItems(val: Menu) {
    this._footerMenu.next(val);
  }

  protected removeFooterMenu() {
    this.setFooterMenuItems = null;
  }

  public fetchHorizontalMenuItems(): void {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.set('name', 'Horizontal');
      this.platformHttpService.get(Constants.MENU, httpParams).pipe(
        takeUntil(this.unsubscribe))
        .subscribe((menuItems: Menu) => {
          this.setHorizonalMenuItems = menuItems;
        })
  }
  public fetchVerticalMenuItems(): void {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.set('name', 'Vertical');
      this.platformHttpService.get(Constants.MENU, httpParams).pipe(
        takeUntil(this.unsubscribe))
        .subscribe((menuItems: Menu) => {
          this.setVerticalMenuItems = menuItems;
        })
  }

  public fetchFooterMenuItems(): void {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.set('name', 'Footer');
      this.platformHttpService.get(Constants.MENU, httpParams).pipe(
        takeUntil(this.unsubscribe))
        .subscribe((menuItems: Menu) => {
          this.setFooterMenuItems = menuItems;
        })
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
