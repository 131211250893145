import { Sort } from './sort';

export class PageRequest {

    // Size is limited in the backened
    constructor(public page: Number,
                // public size : Number,
                public sortBy: String,
                public sortOrder: String,
                public sort: Sort[]) {
    }
}
