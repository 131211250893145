import { CompanyService } from '@shop/app/providers/services/company.service';
import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CartService } from '../../../providers/services/cart.service';
import { takeUntil } from 'rxjs/operators';
import { Company } from '@pdp/common';
import { PlatformHttpService } from '../../http/service/platformhttp.service';
import { UserService } from '@shop/app/providers/services/user.service';
import { User } from 'dist/common/lib/models/user';
import { UserStateService } from '@shop/app/providers/services/user-state.service';
import { RequestUtils } from '../../request-utils';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {

    private unsubscribe: Subject<void> = new Subject();
    private company: Company;
    private currentUser: User;
    constructor(public httpClient: HttpClient,
        public router: Router,
        public platformHttpService: PlatformHttpService,
        public userStateService: UserStateService,
        public cartService: CartService,
        private userService: UserService,
        private companyService: CompanyService) {
        this.companyService.getValue.pipe(takeUntil(this.unsubscribe)).subscribe((company: Company) => {
            this.company = company;
            // localStorage.setItem('uti', this.company.uti);
            // localStorage.setItem('tenant', JSON.stringify(company));
            // localStorage.setItem('mediaKey', this.company.mediaKey);
        })
        this.userStateService.getValue.pipe(takeUntil(this.unsubscribe)).subscribe((user: User) => {
            this.currentUser = user;
        })
    }

    public logoutHome() {
        this.logout();
        // this.logout('/');
    }

    public logoutNoRedirect() {
        this.logout();
    }

    public logoutSessionExpired() {
        this.logout();
        // this.logout('/account/members/login/session-expired');
    }

    public logout() {
        RequestUtils.logRequest('Initiating logout...');
        this.platformHttpService.post(`/logout`, null, null, true).pipe(takeUntil(this.unsubscribe))
            .subscribe((res: HttpResponse<any>) => {
                RequestUtils.logRequest('Logout response', { status: res.status });
                this.userStateService.remove();
                let logoutUri = res.headers.get('Location') || '';
                RequestUtils.logRequest('Logout URI', { logoutUri });
                if (logoutUri) {
                    window.location.href = logoutUri;
                }
                RequestUtils.logRequest('Redirecting to home...', { logoutUri: logoutUri });
            });
    }

    /**
     * If we are already logged in this will just fetch a user, 
     * if not PlatformRequestInterceptor will initiate the login flow when it catches 401
     */
    public maybeInitiatedAuthentication() {
        RequestUtils.logRequest('Maybe initiated authentication....');
        this.platformHttpService.get(`/account/userinfo`)
            .pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
                RequestUtils.logRequest('Authentication response', { user });
                this.userStateService.setCurrentUser(user);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

