export class TableItemValidState {

    constructor(public itemId: any,
                public propertyName: string,
                public errorMessage?: string) { }

    public static of(itemId: any, propertyName: string): TableItemValidState;
    public static of(itemId: any, propertyName: string, errorMessage: string): TableItemValidState;
    public static of(itemId: any, propertyName: string, errorMessage?: string): TableItemValidState {
        return new TableItemValidState(itemId, propertyName, errorMessage);
    }
}
