import { Company } from '@pdp/common';
import { Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CompanyService } from './company.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RequestUtils } from '@shop/app/common/request-utils';
declare var gtag
/**
 * Google Analytics Service - captures app behaviors and sends them to Google Analytics (GA).
 * Presupposes that GA script has been loaded from a script on the host web page.
 * Associates data with a GA "property" from the environment (`gaId`).
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  // https://fluin.io/blog/google-analytics-with-tag-manager-and-angular
  // https://stackoverflow.com/questions/45758852/angular-4-using-google-analytics
  // https://filipmolcik.com/google-analytics-%F0%9F%93%88-in-angular-2-app-example/
  // https://www.napkyn.com/2018/05/02/deploying-google-analytics-for-angular-5/
  private previousUrl: string;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private router: Router,
    private meta: Meta,
    private companyService: CompanyService) {
    this.companyService.getValue.pipe(takeUntil(this.unsubscribe)).subscribe((company: Company) => {
      if (company) {
        if (company.allCompanySettings.gaId) {
          this.initialAnalytics(company.allCompanySettings.gaId);
        }
        if (company.allCompanySettings.googleVerificationCode) {
          this.initialGoogleVerificationCode(company.allCompanySettings.googleVerificationCode);
        }
      }
    })
  }

  private initialGoogleVerificationCode(code: string) {
    this.meta.removeTag("name='google-site-verification'")
    this.meta.addTags([
      { name: 'google-site-verification', content: code }
    ]);

  }
  private initialAnalytics(gaId: string) {
    if (!gaId) {
      RequestUtils.logRequest('No GA ID found');
      return;
    }
    RequestUtils.logRequest('Initiating analytics ', { gaId });
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaId;
    document.head.prepend(script);
    this.router.events.subscribe(event => {
      if (!environment.production) {
        // not production do not send
        return;
      }
      if (event instanceof NavigationEnd) {
        const url = event.url;
        if (url === this.previousUrl || !environment.production) {
          return;
        }
        this.previousUrl = url;
        gtag('config', gaId, { 'page_path': url });
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
