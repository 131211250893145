import { Content } from './content';
import { LocalizedColumnName } from './localized-column-name';

export class TableItem {

  public cssClassesFlat: string;

  constructor(public cssClasses: string[],
              public localizedColumn: LocalizedColumnName | string,
              public propertyName: string,
              public content: Content = new Content()) {
                this.cssClasses = cssClasses.concat('item');
                this.cssClassesFlat = this.cssClasses.join(' ');
              }

  // public getItemClass(record: Entity): string {
  //   const baseClasses: string = this.cssClassesFlat + ' ' + 'item';
  //   if (record && this.propertyName) {
  //     let classDefinedClasses: string = record.getItemClassByProperty(this.propertyName);
  //     return classDefinedClasses = classDefinedClasses + ' ' + baseClasses;
  //   } else {
  //     return baseClasses;
  //   }
  // }
}
