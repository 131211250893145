import { LocalizedColumNameItem } from './localized-column-name-item';

export class LocalizedColumnName {

    constructor(public items: LocalizedColumNameItem[], public defaultColumnName: string) { }

    public currentLocaleColumnName: string;
    public getColumNameForLocale(): string {
        if (typeof window !== 'undefined') {
            // this is browser
            const locale: string = localStorage.getItem('locale');
            switch (locale) {
                case 'en-US': return this.defaultColumnName;
                case 'en': return this.defaultColumnName;
                case 'sr': {
                    for (const item of this.items) {
                        if (item.localeId === locale) {
                            return item.columnName;
                        }
                    }
                    return this.defaultColumnName;
                }
                case 'sr-Latn': {
                    for (const item of this.items) {
                        if (item.localeId === locale) {
                            return item.columnName;
                        }
                    }
                    return this.defaultColumnName;
                } default: {
                    return this.defaultColumnName;
                }
            }
        } else {
            // this is nodejs ssr server
            return this.defaultColumnName;
        }
    }

    public assigntColumnNameForCurrentLocale(): LocalizedColumnName {
        this.currentLocaleColumnName = this.getColumNameForLocale();
        return this;
    }
}
