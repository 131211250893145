import { PipesModule } from '@pdp/pipes';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Inject, NgModule, SimpleChanges } from '@angular/core';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';

@Component({
  selector: 'pdp-two-column-text-dynamic',
  templateUrl: './two-column-text-dynamic.component.html',
  styleUrls: ['./two-column-text-dynamic.component.scss']
})
export class TwoColumnTextDynamicComponent implements OnInit {

  private environment: any;

  @Input()
  public items: ItemToCategoryToComponent[];

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(@Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

  public ngOnChanges(value: SimpleChanges) {
    // console.log(this.items)
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
  declarations: [
    TwoColumnTextDynamicComponent
  ],
  providers:[]
})
class TwoColumnTextModule {}