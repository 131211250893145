export class Constants {

    // Store
    public static STORE = '/store';
    public static ELIGIBLE = '/eligible';
    public static BLOG = '/blog';
    // Catalog
    public static RATING = '/rating';
    public static CATALOG = '/catalog';
    public static PRODUCT = '/product';
    public static CATEGORY = '/category';
    public static INFO = '/info';
    public static INFO_PAGE = '/info-page';
    public static BANNER = '/banner';
    public static ANNOUNCEMENT ='/announcement';
    public static INK_TONER = '/ink-toner-all';
    public static MENU = '/menu';
    public static COMPANY = '/company';
    // search
    public static SEARCH = '/search';


    public static sortBy = class {
        public static PRICE_WITHOUT_TAX = 'priceWithoutTax';
        public static PRICE = 'price';
        public static BEST_SELLER_RANK = 'bestsellerRank ';
    };

    public static SortOrder = class  {
        public static ASC = 'ASC';
        public static DESC = 'DESC';
    };

    public static cart = '/cart';
    public static add = '/add';
    public static REMOVE = '/remove';
    public static UPDATE_QUANTITY = '/update-quantity';
    public static MERGE_ANONYMOUS = '/merge-anonymous';
    public static FOR_ORDER = '/for-order';
    public static HISTORY = '/history';
    public static CHECKOUT = '/checkout';
    public static FOP = '/fop';

    public static SHIPPING = '/shipping';
    public static UPDATE_SHIPPING_FOR_ORDER = '/update-shipping-for-order';
    public static UPDATE_SHIPPING_ADDRESS_FOR_ORDER = '/update-shipping-address-for-order';
    // Customer
    public static CUSTOMER = '/customer';
    public static FORGOT_PASSWORD = '/forgot-password';
    public static VERIFY_PASSWORD_TOKEN = '/verify-password-token';
    public static RESET_PASSWORD = '/reset-password';
    public static PROFILE = '/profile';
    public static NEWSLETTER = '/newsletter';
    public static ADDRESS = '/address';
    public static DEFAULT = '/default';
    public static HD_JDL = '/hd-jdl';

    public static BEST_SELLER_RANK = 'bestsellerRank ';

    public static Platform = class {
        public static SESSION_NAME = 'JSESSIONIDPLATFORM';

    };
}
