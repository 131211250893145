import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, SimpleChanges, OnChanges, Inject, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';
import { ResourceType } from '../../models/resource-type';

@Component({
  selector: 'pdp-single-image-dynamic',
  templateUrl: './single-image-dynamic.component.html',
  styleUrls: ['./single-image-dynamic.component.scss']
})
export class SingleImageDynamicComponent implements OnInit, OnChanges {

  private environment: any;

  @Input()
  public resourceType: ResourceType;

  @Input()
  public items: ItemToCategoryToComponent[];
  public singleItem: ItemToCategoryToComponent;

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(public router: Router,
    @Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void { }

  public navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceType']) {
      if (this.items && this.items[0]) {
        this.singleItem = this.items[0];
      }
    }
  }
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    SingleImageDynamicComponent
  ],
  providers:[]
})
class ProductSliderTwoModule {}