import { PipesModule } from '@pdp/pipes';
import { ItemToCategoryToComponent } from './../../models/item-to-category-to-component';
import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, Input, Inject } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router } from '@angular/router';

@Component({
  selector: 'pdp-expansion-panel-dynamic',
  templateUrl: './expansion-panel-dynamic.component.html',
  styleUrls: ['./expansion-panel-dynamic.component.scss']
})
export class ExpansionPanelDynamicComponent implements OnInit {

  private environment: any;
  @Input() 
  public items: ItemToCategoryToComponent[];

  constructor(public router: Router,
    @Inject('currentEnvironment') environment) {
      this.environment = environment;
     }

  ngOnInit(): void {}
}

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    PipesModule
  ],
  declarations: [
    ExpansionPanelDynamicComponent
  ],
  providers:[]
})
class ExpansionPanelDynamicModule {}