import { LocalizedColumnName } from './localized-column-name';
import { LocalizedColumNameItem } from './localized-column-name-item';
import { Locale } from './locale';


export class LocalizedColumnFactory {
    constructor() {
        throw Error();
    }

    public static id_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Id');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Id');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Id');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static actions_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Actions');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Akcije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Actions');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static localeCode_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Locale');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Lokalizacija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Locale');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static essential_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Essential');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Essential');
        const srLatn: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR, 'Essential');
        const enUS: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN, 'Essential');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr,enUS,srLatn), 'Essential');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static venture_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Venture');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR, 'Venture');
        const srLatn: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Venture');
        const enUS: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN, 'Venture');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr,enUS,srLatn), 'Venture');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static business_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Business');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR, 'Business');
        const srLatn: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Business');
        const enUS: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN, 'Business');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr,enUS,srLatn), 'Business');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static pro_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Pro');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR, 'Pro');
        const srLatn: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Pro');
        const enUS: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN, 'Pro');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr,enUS,srLatn), 'Pro');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static enterprise_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Enterprise');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Enterprise');
        const srLatn: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR, 'Enterprise');
        const enUS: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN, 'Enterprise');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr,enUS,srLatn), 'Enterprise');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    } 
    
    public static cancel_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Cancel');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Otkaži');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Cancel');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static noDeliveryDate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'No Delivery Date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Bez datuma isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'No Delivery Date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static manufactureOrder_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Manufacture Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Proizvodni nalog');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Manufacture Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static manufactureOrders_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Manufacture Orders');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Nalozi proizvodnje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Manufacture Orders');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static manufacture_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Manufacture');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Proizvodnja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Manufacture');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    
    public static salesOrderFulfilment_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'SO Fulfilment');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Realizacija Narudžbine');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'SO Fulfilment');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static skuId_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'SKU');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'SKU');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'SKU');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static currencyExchangeRates_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Currency Exchange Rates');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kursna lista');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Currency Exchange Rates');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static inventoryAdjustmentReason_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Inventory Adjustment Reason');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Razlog korekcije inventara');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Inventory Adjustment Reason');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static inventoryAdjustmentReasons_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Inventory Adjustment Reasons');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Razlozi korekcije inventara');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Inventory Adjustment Reasons');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static banner_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Banner');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Baner');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Banner');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shippingOptions_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping Options');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Opcije isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping Options');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shippingType_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping Type');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Tip isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping Type');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shippingRate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping Rate');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Pravila isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping Rate');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shippingMethod_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping Method');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Metode isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping Method');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    
    public static carrierName_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Carrier Name');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kurir');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Carrier Name');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static productGroup_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Product Group');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Grupa Proizvoda');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Product Group');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static blog_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Blog');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Blog');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Blog');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static warehouseTransfer_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Warehouse Transfer');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Magacinski transfer');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Warehouse Transfer');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static warehouseTransfers_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Warehouse Transfers');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Magacinski transferi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Warehouse Transfers');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static customers_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Customers');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kupci');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Customers');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static travelOrder_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Travel Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Putni nalog');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Travel Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static travelOrders_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Travel Orders');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Putni nalozi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Travel Orders');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static bom_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Bom');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Bom');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Bom');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static materialSpecification_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Materials');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Trebovanje materijala');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Materials');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static details_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Details');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Detalji');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Details');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static administrator_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Admin');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Administrator');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Admin');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static administrators_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Administrators');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Administratori');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Administrators');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static store_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Store');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Prodavnica');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Store');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static generalSettings_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'General');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Osnovno podešavanje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'General');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static fulfilments_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Fulfilments');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Realizacije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Fulfilments');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shipments_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipments');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipments');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static fulfilment_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Fulfilment');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Realizacija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Fulfilment');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static linkedFulfilment_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Linked Fulfilment');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povezana realizacija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Linked Fulfilment');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }


    public static linkedPurchaseOrders_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Linked Purchase Orders');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povezane kupovine');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Linked Purchase Orders');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static linkedSOFulfilments_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Linked SO Fulfilments');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povezane prodajne realizacije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Linked SO Fulfilments');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static linkedManufactureOrders_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Linked Manufacture Orders');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povezani nalozi proizvodnje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Linked Manufacture Orders');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static linkedMaterialSpecifications_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Linked Material Specifications');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povezana trebovanja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Linked Material Specifications');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }



    public static menu_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Menu');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Meni');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Menu');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static menuItem_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Menu Item');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Stavke menija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Menu');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static info_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Info');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Info');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Info');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static infoPages_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Info Pages');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Info stranice');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Info Pages');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static stockAdjustment_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Stock Adjustment');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Korekcija Inventara');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Stock Adjustment');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static stockAdjustments_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Stock Adjustments');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Korekcije Inventara');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Stock Adjustments');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static customer_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Customer');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kupac');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Customer');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static stockOverview_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Stock overview');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Pregled inventara');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Stock overview');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static stockOnHandValue_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Stock On Hand Value');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Vrednost lagera');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Stock On Hand Value');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static stockOnHand_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Stock On Hand');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Na lageru');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Stock On Hand');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static dueDate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Due Date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dospeva');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Due Date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static externalId_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'External Id');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Spoljna šifra');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'External Id');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static externalName_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'External Name');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Spoljni naziv');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'External Name');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static name_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Name');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Naziv');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Name');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static description_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Description');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Opis');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Description');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static language_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Language');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Jezik');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Language');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static author_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Author');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Autor');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Author');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static width_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Width');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Širina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Width');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static height_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Height');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Visina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Height');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static unitOfMeasure_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Unit Of Measure');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Jedinica Mera');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Unit Of Measure');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static days_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Days');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dana');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Days');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static type_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Type');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Tip');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Type');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static return_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Return');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povraćaj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Return');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static reason_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Reason');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Razlog');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Reason');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static value_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Value');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Vrednost');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Value');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static validate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Validate');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Validiraj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Validate');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static vehicle_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Vehicle');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Vozilo');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Vehicle');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static driver_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Driver');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Vozač');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Driver');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static company_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Company');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kompanija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Company');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static firstLastName_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'First & Last name');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ime i prezime');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'First & Last name');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static taxNumber_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'VAT');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'PIB');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'VAT');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static rate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Rate');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Stopa');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Rate');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static plateNo_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Plate No.');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Registarski broj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Plate No.');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }



    
    public static trackingCode_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Tracking Code');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Šifra za praćenje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Tracking Code');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static code_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Code');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Šifra');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Code');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static contact_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Contact');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kontakt');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Contact');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static email_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Email');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Email');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Email');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static notLocked_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Not Locked');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Otključan');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Not Locked');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static newsLetter_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Newsletter');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Vesti');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Newsletter');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static purchase_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Purchase');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kupovina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Purchase');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static purchases_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Purchases');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kupovine');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Purchases');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static emailForPurchases_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Email for purchases');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Email za narudžbine');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Email for purchases');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static paymentTerms_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Payment Terms');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Uslovi Plaćanja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Payment Terms');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static bank_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Bank');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Banka');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Bank');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static back_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Back');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Nazad');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Back');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static phoneNumber_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Phone Number');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Telefon');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Phone Number');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static address_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Address');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Adresa');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Address');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static city_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'City');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Grad');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'City');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static postalCode_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Postal Code');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Poštanski Broj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Postal Code');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static paid_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Paid');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Plaćeno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Paid');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static country_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Country');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Država');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Country');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static placeDate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Placed date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Naručeno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Placed date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static deliveryDate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Delivery date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Datum isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Delivery date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static deliveryDateMO_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'MO Delivery date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Datum isporuke proizvodnje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'MO Delivery date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static deliveryDatePO_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'PO Delivery date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Datum isporuke kupovine');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'PO Delivery date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static fieldChanged_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Field');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Polje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Field');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static prevousValue_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Previous value');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Prethodna vrednost');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Previous value');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static notifyCustomer_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Notify customer');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Obavestiti kupca');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Notify customer');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static newValue_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'New value');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Nova vrednost');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'New value');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static creditReason_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Credit Reason');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Razlog povraćaja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Credit Reason');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static creditNote_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Credit Note');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povraćaj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Credit Note');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static creditNotes_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Credit Notes');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povraćaji');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Credit Notes');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static createdBy_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Created by');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dodao');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Created by');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static createdDate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Created Date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kreirano');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Created Date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static reserved_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Reserved');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Rezervisano');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Reserved');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static available_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Available');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Raspoloživo');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Available');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }


    public static expectedPurchases_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Expected Purchases');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Očekivana nabavka');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Expected Purchases');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }


    public static expectedManufactureOrder_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Expected MO');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Očekivana proizvodnja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Expected MO');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    

    public static registered_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Registered Date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Registrovan');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Registered Date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static completedDate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Completed Date');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Završeno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Completed Date');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static status_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Status');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Status');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Status');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static departureLocation_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Departure Location');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Polazak');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Departure Location');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shipping_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Isporuka');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static empty_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, ' ');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, ' ');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), ' ');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shippingOption_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping option');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Opcija isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping option');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shippingAddress_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping address');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Adresa isporuke');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping address');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static unitCost_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Unit cost');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Cena koštanja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Unit cost');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static unitPrice_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Unit price');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Cena po komadu');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Unit price');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static shippingCost_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipping cost');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Trosak transporta');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipping cost');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static newState_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'New state');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Novo stanje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'New state');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static priceWithoutTax_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Price without tax');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Cena (bez PDV-a)');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Price without tax');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static basePrice_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Base price without tax');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Osnovna Cena (bez PDV-a)');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Base price without tax');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static salePriceWithoutTax_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Discount price');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Snižena cena');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Discount price');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static salePricePercentage_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Discount');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Popust');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Discount');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    

    public static total_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Total');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ukupno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Total');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static subTotal_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Sub Total');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ukupno bez PDV');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Sub Total');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static delete_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Delete');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Brisanje');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Delete');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static title_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Title');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Naslov');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Title');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static sent_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Sent by');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Poslao');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Sent by');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static sentAt_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Sent at');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Vreme slanja');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Sent at');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static sentFrom_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Sent from');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Adresa pošiljaoca');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Sent from');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static sentTo_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Sent to');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Adresa primaoca');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Sent to');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static attachment_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Attachment');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Priložen dokument');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Attachment');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static success_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Success');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Success');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Success');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static default_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Default');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Podrazumevan');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Default');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static edit_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Edit');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ažuriraj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Edit');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static remove_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Remove');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ukloni');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Remove');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static removeItem_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Remove item');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ukloni element');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Remove item');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static currentSupplier_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Current supplier');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Trenutni dobavljač');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Current supplier');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static newDefaultSupplier_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'New default supplier');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Novi podrazumevani dobavljač');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'New default supplier');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static currency_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Currency');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Valuta');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Currency');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static myCurrency_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'My Currency');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Moja Valuta');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'My Currency');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static foreignCurrency_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Foreign Currency');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Strana Valuta');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Foreign Currency');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static buyRate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Buy Rate');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kupovni kurs');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Buy Rate');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static sellRate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Sell rate');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Prodajni kurs');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Sell rate');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static warehouse_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Warehouse');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Skladište');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Warehouse');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static warehouses_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Warehouses');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Magacini');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Warehouses');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static fromWarehouse_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'From warehouse');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Iz magacina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'From warehouse');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static toWarehouse_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'To warehouse');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'U magacin');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'To warehouse');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    // TODO: - da li je cena sa popustom i cena sa rabatom isto
    public static discountedPrice_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Discounted price');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Cena sa popustom');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Discounted price');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static discountPercentage_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Discount %');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Popust %');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Discount %');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static taxRate_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Tax Rate');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Poreska stopa');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Tax Rate');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static tax_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Tax');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'PDV');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Tax');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static receipted_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Receipted');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Primljeno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Receipted');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static transactionType_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Transaction type');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Tip transakcije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Transaction type');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static transactionReference_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Transaction reference');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Referenca transakcije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Transaction reference');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static reference_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Reference');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Referenca');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Reference');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static transactionLineReference_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Transaction line reference');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Referenca stavke transakcije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Transaction line reference');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static sourceINTransaction_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Source IN transaction');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dolazna transakcija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Source IN transaction');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }


    public static quantityToShip_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Quantity To Ship');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Količina za isporuku');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Quantity To Ship');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static quantity_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Quantity');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Količina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Quantity');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static ordered_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Ordered');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Naručeno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Ordered');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static image_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Image');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Slika');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Image');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static imageWithText_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Image With Text');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Slika sa tekstom');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Image With Text');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static imageGallery_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Image Gallery');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Galerija slika');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Image Gallery');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static featureImage_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Feature Image');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Slika');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Feature Image');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static displayOrder_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Display Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Redosled');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Display Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static order_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Narudžbenica');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static orders_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Orders');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Narudžbenice');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Orders');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static active_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Active');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Aktivan');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Active');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static product_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Product');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Proizvod');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Product');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static products_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Products');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Proizvodi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Products');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static productBundles_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Product Bundles');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Grupe proizvoda (Bundles)');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Product Bundles');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static productOptions_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Product Options');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Opcije proizvoda');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Product Options');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static shipped_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Shipped');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Poslato');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Shipped');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static fulfiled_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Fulfilled');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Realizovano');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Fulfilled');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static invoiced_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Invoiced');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Fakturisano');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Invoiced');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static supplier_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Supplier');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dobavljač');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Supplier');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static suppliers_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Suppliers');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dobavljači');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Suppliers');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static supplierName_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Supplier name');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Naziv dobavljača');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Supplier name');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    // buttons
    public static save_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Save');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Snimi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Save');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static proformaInvoice_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Proforma Invoice');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Profaktura');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Proforma Invoice');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static downloadProformaInvoice_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Download Proforma Invoice');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Preuzmi predračun');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Download Proforma Invoice');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static downloadWorkOrder_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Download Work Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Preuzmi radni nalog');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Download Work Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static download_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Download');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Preuzmi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Download');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static downloadSoFulfilmentDocument_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Download Fulfilment Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Preuzmi nalog za isporuku');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Download Fulfilment Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static downloadMoFulfilmentDocument_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Download Manufacture Fulfilment Order');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Preuzmi nalog za proizvodnju');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Download Manufacture Fulfilment Order');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static invoice_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Invoice');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Faktura');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Invoice');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static add_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Add');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Dodaj');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Add');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static quote_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Quote');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Predračun');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Quote');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static quotes_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Quotes');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Ponude');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Quotes');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static place_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Place');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Naruči');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Place');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    
    public static processing_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Processing');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'U obradi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Processing');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    
    public static pending_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Pending');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Na čekanju');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Pending');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static dispatch_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Dispatch');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Pošalji');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Dispatch');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static createReceipt_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Create receipt');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kreiraj prijemnicu');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Create receipt');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static createDocuments_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Create document');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kreiraj dokument');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Create document');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static confirmReceipt_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Confirm receipt');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Potvrdi prijemnicu');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Confirm receipt');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static completed_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Complete');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Završi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Complete');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static returnGoods_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Return goods');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Povraćaj robe');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Return goods');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static category_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Category');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kategorija');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Category');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static component_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Component');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Komponenta');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Component');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static twoTextColumns_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Two Columns Text Component');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Tekstualna komponenta sa dve kolone');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Two Columns Text Component');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static oneTextColumn_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'One Column Text Component');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Tekstualna komponenta sa jednom kolonom');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'One Column Text Component');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static categories_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Categories');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Kategorije');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Categories');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static tags_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Tags');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Tagovi');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Tags');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static views_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Views');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Pregleda');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Views');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static url_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Url');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Url');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Url');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static manufacturer_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Manufacturer');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Proizvođač');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Manufacturer');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static required_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Required');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Obavezno');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Required');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }


    public static minWeight_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Min Weight');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Minimalna težina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Min Weight');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static maxWeight_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Max Weight');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Maksimalna težina');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Max Weight');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static minSubTotal_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Min SubTotal');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Minimalna Vrednost bez PDV');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Min SubTotal');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static maxSubTotal_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Max SubTotal');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Maksimalna vrednost bez PDV');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Max SubTotal');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }

    public static price_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Price');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Cena');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Price');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
    public static priceAdjustment_(): LocalizedColumnName {
        const en: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.EN_US, 'Price Adjustment');
        const sr: LocalizedColumNameItem = new LocalizedColumNameItem(Locale.SR_LATN, 'Korekcija cene');
        const localizedColumnName: LocalizedColumnName = new LocalizedColumnName(new Array(en, sr), 'Price Adjustment');
        return localizedColumnName.assigntColumnNameForCurrentLocale();
    }
}
