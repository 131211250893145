import { WithStatusEntity } from './with-status-entity';
import { TableItemLabeledValue } from './table-item-labeled-value';

export class OrderLog extends WithStatusEntity {

    public orderLogType: string;
    public orderId: number;
    public createdDate: Date;
    public createdBy: string;
    public notifyCustomer: boolean;

    constructor() {
        super();
    }

    public getItemValueByProperty(propertyName: string): TableItemLabeledValue {
        if (this.hasOwnProperty(propertyName)) {
            switch (propertyName) {
                case 'status':
                    return TableItemLabeledValue.of(this.status ? this.status.name.replace(/_/g, ' ') : '');
                case 'notifyCustomer': {
                    if (this.notifyCustomer) {
                        return TableItemLabeledValue.of('circle-success');
                    } else {
                        return TableItemLabeledValue.of('circle-fail');
                    }
                }
            }
            return TableItemLabeledValue.of(this[propertyName]);
        } else {
            return TableItemLabeledValue.empty();
        }
    }
}
