import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, SimpleChanges, Inject, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';

@Component({
  selector: 'pdp-parallax-banner-two',
  templateUrl: './parallax-banner-two.component.html',
  styleUrls: ['./parallax-banner-two.component.scss']
})
export class ParallaxBannerTwoComponent implements OnInit {

  private environment: any;

  @Input()
  public items: ItemToCategoryToComponent[];
  public item: ItemToCategoryToComponent;

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(public router: Router, 
    @Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void { }

  public navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.items && this.items.length > 0) {
      let singleItem = this.items[0];
      this.item = singleItem;
    }
  }
}


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ParallaxBannerTwoComponent
  ],
  providers:[]
})
class ParallaxModule {}