import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor(private spinnerService: SpinnerService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    if(this.requests.length === 0) {
      this.spinnerService.isLoading.next(false);

      // when last request resolves, loader will just dissapear abruptly.
      // Add this timeout so spinner disapears gracefuly.
      // this adds 400ms, so tweak it or remove it completely if production works slow enough :)
      // setTimeout(() => {
      //   this.spinnerService.isLoading.next(false);
      // }, 300)
    } else {
      this.spinnerService.isLoading.next(true);
    }
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(req.url.startsWith('http')) {
      this.requests.push(req);
      // console.log("No of requests--->" + this.requests.length);
      this.spinnerService.isLoading.next(true);
      return Observable.create(observer => {
        const subscription = next.handle(req)
          .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                this.removeRequest(req);
                observer.next(event);
              }
            },
            err => {
              this.removeRequest(req);
              observer.error(err);
            },
            () => {
              this.removeRequest(req);
              observer.complete();
            });
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    } else {
      return next.handle(req);
    }
  }
}