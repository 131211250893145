import { MonetaryAmount } from './monetary-amount';
import { BillableEntityItem } from './billable-entity-item';
import { TableItemLabeledValue } from './table-item-labeled-value';
import { ContentType } from './content-type';

export class OrderItem extends BillableEntityItem {
    public orderItemAttributes: any[];
    public discountAllowed: boolean;
    public totalItemTax: MonetaryAmount;
    public orderItemSubtotal: MonetaryAmount;
    public orderId: number;
    public categoryId: number;
    public url: string;
    public orderItemImageUrl: string;
    public orderItemImageTitle: string;
    public orderItemImageAltText: string;
    public discountApplied: boolean;
    public shipped: number;
    public discountPercentage: number;

    public getItemValueByProperty(propertyName: string): TableItemLabeledValue {

        switch (propertyName) {
            case 'discountPercentage':
                if (this.discountAllowed) {
                    return TableItemLabeledValue.of(this.discountPercentage.toFixed(2), null, ContentType.TEXT);
                }
            case 'priceWithoutTax':
                return TableItemLabeledValue.of(this.priceWithoutTax, null, ContentType.TEXT);
            default:
                return super.getItemValueByProperty(propertyName);
        }
    }

    public getItemClassByProperty(propertyName: string): string {
        return super.getItemClassByProperty(propertyName);
    }
}
