import {
    throwError as observableThrowError,
    Observable,
    Subject,
} from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { RequestUtils } from '../../common/request-utils';
import { CompanyService } from '../services/company.service';
import { Company } from '@pdp/common';
import { PlatformAwareService } from '../services/platform-aware.service';

@Injectable({
    providedIn: 'root',
})
export class PlatformRequestInterceptor implements HttpInterceptor {
    public company: Company;
    private unsubscribe: Subject<void> = new Subject();

    constructor(private companyService: CompanyService,
        private platformAwareService: PlatformAwareService
    ) {
        this.companyService.getValue.pipe(takeUntil(this.unsubscribe)).subscribe((company: Company) => {
            this.company = company;
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return next.handle(req).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    RequestUtils.logRequest('Error in interceptor', { error });
                    switch ((<HttpErrorResponse>error).status) {
                        case 0:
                            // spring cloud gateway during get requests will set origin header null during forwarding of unathorized request to authorization as mentioned here https://github.com/eugenp/tutorials/issues/12166
                            // in that case authorization server returns status 0 CORS error
                            // in such cases redirect to authorization server to initiate authorization flow
                            window.location.href = this.getInitiatedLoginUrl(this.company);;
                            return observableThrowError(error);
                        case 401:
                            return this.handle401Error(error);
                        case 404:
                            return this.handle404Error(error);
                        default:
                            return observableThrowError(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

    handle401Error(error: HttpErrorResponse) {
        RequestUtils.logRequest('401 - Unauthorized', { error });
        RequestUtils.logRequest('401 - index', { index: error.url.indexOf('logout')});

        if (this.platformAwareService.isBrowser) {
            if (error.url.indexOf('logout') > 0) {
                // 401 during logout. Something is awfully suspicious with this user
                window.location.href = this.company.domain ? this.company.domain : RequestUtils.defaultRedirectIfSomethingWrong;
            } else {
                window.location.href = this.getInitiatedLoginUrl(this.company);
            }
        } else {
            RequestUtils.logRequest('401 - Skipping redirection to initial login for server side', { url: error['url'] });
        }
        return observableThrowError(error);
    }

    handle404Error(error) {
        RequestUtils.logRequest('404 - Not Found', { error });
        return observableThrowError(error);
    }

    handleDefaultError(error) {
        return observableThrowError(error);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getInitiatedLoginUrl = (company: Company): string => `${company.apiBaseUrl}/pdp/authorize?uti=${company.uti}`;
    private getInitiateRegisterUrl = (company: Company): string => `${this.company.apiBaseUrl}/pdp/register`;
}
