import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl, SafeScript, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(public _sanitizer?: DomSanitizer) {}
  /**
   * If additional headers are needed use utiAware pipe
   */
  transform(value: any, args?: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl  {
    if(args) {
      switch (args) {
        case 'HTML': return this._sanitizer.bypassSecurityTrustHtml(value);
        case 'STYLE': return this._sanitizer.bypassSecurityTrustStyle(`url(${value})`);
        case 'SCRIPT': return this._sanitizer.bypassSecurityTrustScript(value);
        case 'URL': return this._sanitizer.bypassSecurityTrustUrl(value);
        case 'RESOURCE': return this._sanitizer.bypassSecurityTrustResourceUrl(value);
        case 'RESOURCE_URL': return this._sanitizer.bypassSecurityTrustResourceUrl(`url(${value})`);
        default: throw new Error(`Invalid safe type specified: ${args}`);
      }
    } else {
      return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}