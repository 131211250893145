import { CommonModule } from '@angular/common';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';
import { Router } from '@angular/router';
import { Component, OnInit, Input, SimpleChanges, Inject, NgModule } from '@angular/core';
// i na offeru i na shopu je isti baseUrl. Tako da cemo odabrati jedan da ne bi kopirali environment file u @pdp/common
@Component({
  selector: 'pdp-gallery-two',
  templateUrl: './gallery-two.component.html',
  styleUrls: ['./gallery-two.component.scss']
})
export class GalleryTwoComponent implements OnInit {

  private environment: any;
  @Input() 
  public items: ItemToCategoryToComponent[];

  constructor(public router: Router,
    @Inject('currentEnvironment') environment) {
      this.environment = environment;
     } 

  ngOnInit(): void {}

  public navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  public ngOnChanges(value: SimpleChanges) {
    // if (this.items) {
    //   for (let item of this.items) {
    //     item.media.url = this.mediaKeyQueryParamAwarePipe.transform(this.environment.baseUrl + '/images/category-components/' + item.media.url);
    //   }
    // }
  }

}


// Svaka komponenta koja se dinamicki ucitava mora da ima svoj privatni modul da bi se importovali dependency poput
@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    GalleryTwoComponent
  ],
  providers:[]
})
class GalleryTwoModule {}