import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';
import { Component, OnInit, Input, SimpleChanges, Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'pdp-image-text-box-over-dynamic',
  templateUrl: './image-text-box-over-dynamic.component.html',
  styleUrls: ['./image-text-box-over-dynamic.component.scss']
})
export class ImageTextBoxOverDynamicComponent implements OnInit {

  private environment: any;
  @Input()
  public items: ItemToCategoryToComponent[];
  public singleItem: ItemToCategoryToComponent; //this component always works with single item

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(public router: Router, 
    @Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void { }

  public navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  public ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.items && this.items.length > 0) {
      this.singleItem = this.items[0];
    }
  }
}



@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  declarations: [
    ImageTextBoxOverDynamicComponent
  ],
  providers:[]
})
class ImageOverBoxModule {}