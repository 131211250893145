// import { PlatformAwareService } from './../../../providers/services/platform-aware.service';
import { CompanyService } from '@shop/app/providers/services/company.service';
import { Inject, Injectable, LOCALE_ID, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { takeUntil } from 'rxjs/operators';
import { Company } from '@pdp/common';
import { Subject } from 'rxjs';
import { PlatformAwareService } from '@shop/app/providers/services/platform-aware.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformHttpService {
  private unsubscribe: Subject<void> = new Subject();

  private company: Company;
  constructor(public httpClient: HttpClient,

    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(LOCALE_ID) public localeId: string,
    @Optional() @Inject(REQUEST) private request: Request,
    private platformAwareService: PlatformAwareService,
    private companyService: CompanyService) {
    this.companyService.getValue.pipe(takeUntil(this.unsubscribe)).subscribe((company: Company) => {
      this.company = company;
    })
  }

  /**
   *  GET method enhanced with automatic adding of security and other common headers
   */
  get(url: string, params?: HttpParams) {
    return this.requestInternal(url, 'GET', null, params);
  }

  /**
  * Post method enhanced with automatic adding of security and other common headers
  */
  post(url: string, body?: any, params?: HttpParams, observeFullResponse?: boolean) {
    return this.requestInternal(url, 'POST', body, params, observeFullResponse);
  }

  /**
  * PUT method enhanced with automatic adding of security and other common headers
  */
  put(url: string, body: any) {
    return this.requestInternal(url, 'PUT', body, undefined);
  }

  /**
  * DELETE method enhanced with automatic adding of security and other common headers
  */
  delete(url: string, observeFullResponse?: boolean) {
    return this.requestInternal(url, 'DELETE', null, null, true);
  }

  private requestInternal(url: string, method: string, body?: any, httpParams?: HttpParams, observeFullResponse?: boolean) {
    // headers
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('X-PLATFORM-REQUEST', 'pdp-client');
    headers = headers.append('X-PLATFORM-UTI', this.company.uti);
    headers = headers.append('x-platform-ml', true + '');

    if(this.platformAwareService.isServer) {
      headers = headers.append('x-host', this.request.hostname);
    }

    // browser automatically sets Accept-Language to system configured settings. We cannot control language fix to serbian, if desktop keyboard is configured to EN (just an example)
    headers = headers.append('x-platform-accept-language', this.localeId);

    // this works but i did not want to send ?lang=sr-Latn in every request
    // if (!httpParams) {
    //   httpParams = new HttpParams()
    // }
    // httpParams = httpParams.append('lang', this.localeId)

    // if (this.localeId) {
    //   headers = headers.append('Accept-Language', this.localeId);
    // } else {
    //   // on some browsers, due to adblockers or misconfigurations we were unable to save locale to localStorage. send x-platform-ml (missinglocale) header so api can handle default locales for tenant
    //   headers = headers.append('x-platform-ml', true + '');
    // }

    // check because of old browsers
    if (Intl && Intl.DateTimeFormat() && Intl.DateTimeFormat().resolvedOptions()) {
      const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
      headers = headers.append('x-user-timezone', timeZone);
    }
    // Request options
    const requestOptions = {
      body: body,
      observe: observeFullResponse ? 'response' as 'response' : 'body' as 'body',
      headers: headers,
      params: httpParams,
      withCredentials: true
    };
    return this.httpClient.request(method, `${this.company.apiBaseUrl}/pdp${url}`, requestOptions);
    // return this.httpClient.request(method, environment.baseUrlApi + url, requestOptions);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
