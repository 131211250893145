import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PdpIsNotANumber'
})
export class NotANumberPipe implements PipeTransform {

  transform(value: any): boolean {
    if (!value) {
      return false;
    }
    return isNaN(value);
  }
}
