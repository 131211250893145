import { PipesModule } from '@pdp/pipes';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Inject, NgModule } from '@angular/core';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';

@Component({
  selector: 'pdp-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  private environment: any;

  @Input()
  public items: ItemToCategoryToComponent[];

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(@Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void {
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
  declarations: [
    TextComponent
  ],
  providers:[]
})
class TextModule {}