import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, withEnabledBlockingInitialNavigation } from '@angular/router';
import { AuthorizeComponent } from './authorize/authorize.component';

const routes: Routes = [
  // {  path: 'static-content/sitemap.xml', component: SitemapComponent },
  { path: 'authorize', component: AuthorizeComponent },
  { path: '', children: [{ path: '', loadChildren: () => import('./modules/pages.module').then(m => m.PagesModule) }] },
  { path: 'page-not-found', loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: '**', redirectTo: 'page-not-found' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      // preloadingStrategy: PreloadAllModules,
      // Superiorniji scroll to top ukoliko scrollPositionRestoration: 'enabled' ne bude radilo
      // https://medium.com/front-end-weekly/handling-scrolling-on-angular-router-transitions-e7652e57d964
      scrollPositionRestoration: 'enabled',
      // scrollOffset: [0, 0],
      // anchorScrolling: 'enabled',
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
