import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpoBrandListComponent } from './expo-brand-list.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    ExpoBrandListComponent
  ],
  imports: [
    CommonModule, 
    SlickCarouselModule
  ],
  exports: [
    ExpoBrandListComponent
  ],
})
export class BrandListModule {}
