import { PipesModule } from '@pdp/pipes';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Inject, NgModule } from '@angular/core';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';


@Component({
  selector: 'pdp-text-full-width',
  templateUrl: './text-full-width.component.html',
  styleUrls: ['./text-full-width.component.scss']
})
export class TextFullWidthComponent implements OnInit {

  private environment: any;

  @Input()
  public items: ItemToCategoryToComponent[];

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(@Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
  declarations: [
    TextFullWidthComponent
  ],
  providers:[]
})
class TextFullWidthModule {}