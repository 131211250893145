<div *ngIf="items && items.length"  [style.background-color]="singleItem?.contentBackgroundColor">
    <div class="wrapper">
        <ng-container *ngIf="items && singleItem && singleItem.media && singleItem.media.url && resourceType">
            <div class="single-image-dynamic">
                <a *ngIf="singleItem.url" routerLink="{{singleItem.url}}">
                    <img [src]="singleItem.media.url"
                        [alt]="singleItem.media?.altText ? singleItem.media.altText : ''"
                        [title]="singleItem.media?.title ? singleItem.media.title : ''">
                </a>
    
                <img *ngIf="!singleItem.url" [src]="singleItem.media.url"
                    [alt]="singleItem.media?.altText ? singleItem.media.altText : ''"
                    [title]="singleItem.media?.title ? singleItem.media.title : ''">
            </div>
        </ng-container>
        <div *ngIf="singleItem?.content" class="wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
            <span [innerHtml]="singleItem?.content | sanitizeHtml: 'HTML' "> </span>
        </div>
    </div>
</div>
