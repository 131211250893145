import { Injectable } from '@angular/core';
import { PlatformStateService } from './platform-state.service';
import { User } from "@pdp/common";

@Injectable({
  providedIn: 'root'
})
export class UserStateService extends PlatformStateService<User>{

  constructor() {
    super()
  }

  public setCurrentUser(user: User) {
    if (user && 'Anonymous' != user.firstLastName) {
      this.setValue = user;
    } else {
      this.setValue = null;
    }
  }
}
