import { Params } from '@angular/router';
import { ContentType } from './content-type';

export class TableItemLabeledValue {

    constructor(public value: any, public label?: string, public type?: ContentType, public queryParams?: Params, public tooltip?: string) { }

    // prvo moramo da deklarisemo sve overloadovane metode i onda poslednju da implementiramo
    public static of(value: any): TableItemLabeledValue;
    public static of(value: any, label: string): TableItemLabeledValue;
    public static of(value: any, label: string, type: ContentType): TableItemLabeledValue;
    public static of(value: any, label: string, type: ContentType, queryParams: Params): TableItemLabeledValue;
    public static of(value: any, label: string, type: ContentType, queryParams: Params, tooltip: string): TableItemLabeledValue;

    public static of(value: any = '', label?: string, type?: ContentType, queryParams?: Params, tooltip?: string): TableItemLabeledValue {
        value =  value == null ? '' : value;
        return new TableItemLabeledValue(value, label ? label : value, type, queryParams, tooltip);
    }

    public static empty(): TableItemLabeledValue {
        return new TableItemLabeledValue('', '', null);
    }
}
