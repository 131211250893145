import { TableItemLabeledValue } from './table-item-labeled-value';
import { EntityClasses } from './entity-classes';

export abstract class Entity {
  public id: number;
  public canDelete: boolean;

  // buduci da ne mozemo da pozivamo metode iz html, za svaki property koji nam bude trebao u buducnosti da ima css klase,
  // moracemo da kreiramo novi arrayStringova sa drugim nazivom i onda u table-body.html ce imati *ngIf=""
  public classesByPropertyName: EntityClasses;
  public propertyValues: EntityClasses;

  public getItemClassByProperty(propertyName: string): string {
    return '';
  }

  public getItemValueByProperty(propertyName: string): TableItemLabeledValue {
    if (this.hasOwnProperty(propertyName) && this[propertyName]) {
      return TableItemLabeledValue.of(this[propertyName]);
    } else {
      return TableItemLabeledValue.empty();
    }
  }
}
