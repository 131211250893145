import { ShopOrderItem } from './../../models/shop-order-item';
import { Injectable, OnDestroy } from '@angular/core';
import { PlatformHttpService, } from '../../common/http/service/platformhttp.service';
import { Constants } from '../../common/constants';
import { OrderItemRequest } from '../../models/order-item-request';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { OrderCheckoutRequest } from '../../models/order-checkout-request';
import { OrderShippingRequest } from '../../models/order-shipping-request';
import { OrderFopChangeRequest } from '../../models/order-fop-change-request';
import { OrderShippingAddressRequest } from '../../models/order-shipping-address-request';
import { map, takeUntil } from 'rxjs/operators';
import { Order, OrderLog, EntityClasses } from '@pdp/common';

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnDestroy {

  private _order: Subject<any> = new BehaviorSubject([]);
  public order: Observable<Order> = this._order.asObservable();
  private unsubscribe: Subject<void> = new Subject();

  constructor(public platformHttpService: PlatformHttpService) { }

  public updateOrder(order: Order) {
    this._order.next(order);
  }

  public addProduct(orderItemRequest: OrderItemRequest): Observable<Order> {
    return this.platformHttpService.post(Constants.cart + Constants.add, orderItemRequest).pipe(
      map((res: Order) => {
        const order = res;
        this._order.next(this.assignToType(order));
        return order;
      }));
  }

  public removeItemFromCart(orderItemRequest: OrderItemRequest): Observable<Order> {
    return this.platformHttpService.post(Constants.cart + Constants.REMOVE, orderItemRequest).pipe(
      map((res: Order) => {
        this._order.next(this.assignToType(res));
        return res;
      }));
  }
  public updateQuantity(orderItemRequest: OrderItemRequest): Observable<Order> {
    return this.platformHttpService.put(Constants.cart + Constants.UPDATE_QUANTITY, orderItemRequest).pipe(
      map((res: Order) => {
        this._order.next(this.assignToType(res));
        return res;
      }));
  }

  public mergeCartWithAnonymous() {
    return this.platformHttpService.post(Constants.cart + Constants.MERGE_ANONYMOUS);
  }  
  
  public updateShippingForOrder(orderShippingRequest: OrderShippingRequest): Observable<any> {
    return this.platformHttpService.post(Constants.SHIPPING + '/v2' + Constants.UPDATE_SHIPPING_FOR_ORDER, orderShippingRequest).pipe(
      map((res: OrderCheckoutRequest) => {
        const orderCheckoutRequest: OrderCheckoutRequest = res;
        const order: Order = orderCheckoutRequest['order'];
        this._order.next(this.assignToType(order));
        return orderCheckoutRequest;
      }));
  } 
  
  public updateShippingAddressForOrder(orderShippingAddressRequest: OrderShippingAddressRequest) {
    return this.platformHttpService.post(Constants.SHIPPING + '/v2' + Constants.UPDATE_SHIPPING_ADDRESS_FOR_ORDER, orderShippingAddressRequest);
  }

  public getOrder(): void {
    this.platformHttpService.get(Constants.cart)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((order: Order) => {
        const orderAssigned = this.assignToType(order);
        this._order.next(orderAssigned);
      });
  }

  private assignToType(res: Order): Order {
    const typedOrder: Order = Object.assign(new Order(), res);
    if (typedOrder.items) {
      typedOrder.items = typedOrder.items.map(item => Object.assign(new ShopOrderItem(), item));
      for (const item of typedOrder.items) {
        item.propertyValues = new EntityClasses();
        item.classesByPropertyName = new EntityClasses();
      }
    }
    if (typedOrder.orderLogs) {
      typedOrder.orderLogs = typedOrder.orderLogs.map(item => Object.assign(new OrderLog(), item));
    }
    return typedOrder;
  }

  public getOrderHistory(): Observable<any> {
    return this.platformHttpService.get(Constants.cart + Constants.HISTORY);
  }

  public getCheckoutInformation() {
    // When going to checkout page we want to reload order so mini-cart can get updated order from subject
    // This is especially important when product was added to a cart in one browser,
    // the cart will not contan that product in different browser, if client was already logged in
    this.getOrder();
    return this.platformHttpService.get(Constants.CHECKOUT);
  }

  public getFopInformation(): Observable<any> {
    return this.platformHttpService.get(Constants.FOP);
  }

  public getShippingInformation(): Observable<any> {
    return this.platformHttpService.get(Constants.SHIPPING + '/v2/all');
  }

  public checkout(model: any): Observable<Order> {
    return this.platformHttpService.post(Constants.CHECKOUT, model).pipe(
      map((order: Order) => {
        // const order: Order = Order.fromJson(res);
        this._order.next(this.assignToType(order));
        return order;
      }));
  }

  public updateFopForOrder(orderFopChangeRequest: OrderFopChangeRequest) {
    return this.platformHttpService.put(Constants.FOP, orderFopChangeRequest);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
