import { Entity } from './entity';
import { MonetaryAmount } from './monetary-amount';
import { TaxRate } from './tax-rate';
import { TableItemLabeledValue } from './table-item-labeled-value';
import { Currency } from './currency';
import { ItemProductOptionDto } from './ItemProductOptionDto';
import { LocalizedColumnFactory } from '@pdp/localization';
import { ItemType } from './item-type';

export class BillableEntityItem extends Entity {
  public itemAttributes: ItemProductOptionDto[];
  public parentId: number;
  public productId: number;
  public skuId: string;
  public name: string;
  public primaryImageUrl: string;
  public primaryImageAltText: string;
  public primaryImageTitle: string;
  public subTotal: MonetaryAmount;
  public totalTax: MonetaryAmount;
  public taxRate: TaxRate;
  public currency: Currency;
  public total: MonetaryAmount;
  public type: ItemType;
  // only if type === BUNDLE
  public createdBy: string;
  public createdAt: Date;
  public modifiedAt: Date;
  public modifiedBy: string;
  public quantity: number;
  public priceWithoutTax: MonetaryAmount;
  public shippableByCourier: boolean;
  public onePersonDelivery: boolean;
  public dimension: any;
  public weight: any;
  public skuCode: string;
  public productCode: string;

  /**
   * We can use removeItem field state for enabling or disabling of delete operation when condition is something that affects all items,
   * but in cases where some items can be deleted and some not we need to be able to test condition against each individual billable item.
   * Example:
   * 1. Status is PLACED (delete is permitted)
   * 2. We have two different billable items
   *  a) Item a is already fulfiled and for that item delete is not permitted
   *  b) Item b is not fulfiled and delete is permitted
   */
  public removeDisabled = false;

  public getItemPrefix(propertyName: string): string {
    if (this.hasOwnProperty(propertyName)) {
      switch (propertyName) {
        case 'totalTax': return this.totalTax.currency;
        case 'subTotal': return this.subTotal.currency;
        case 'total': return this.total.currency;
      }
    }
  }
  public getItemValueByProperty(propertyName: string): TableItemLabeledValue {
    let response: TableItemLabeledValue;
    switch (propertyName) {
      case 'skuId':
        response = TableItemLabeledValue.of(this.skuCode ? this.skuCode : this.skuId);
        break;
      case 'media':
        response = TableItemLabeledValue.of(this.primaryImageUrl);
        break;
      case 'taxRate':
        if (this.taxRate) {
          response = TableItemLabeledValue.of(this.taxRate.rate.toFixed(2) + ' %');
        }
        break;
      case 'totalTax':
        if (this.totalTax) {
          response = TableItemLabeledValue.of(this.totalTax);
        }
        break;
      case 'subTotal':
        if (this.subTotal) {
          response = TableItemLabeledValue.of(this.subTotal);
        }
        break;
      case 'total':
        if (this.total) {
          response = TableItemLabeledValue.of(this.total);
        }
        break;
      case 'priceWithoutTax':
        if (this.priceWithoutTax) {
          response = TableItemLabeledValue.of(this.priceWithoutTax);
        }
        break;
      case 'removeItem':
        response = TableItemLabeledValue.of(this.removeDisabled, LocalizedColumnFactory.removeItem_().currentLocaleColumnName);
        break;
      case 'name': {
        let stringToPresent = this.name;
        stringToPresent += '<br>';
        stringToPresent += this.skuCode ? this.skuCode : this.skuId;
        if (this.itemAttributes) {
          this.itemAttributes.forEach(attribute => {
            stringToPresent += '<br>' + attribute.name + ': ' + '<strong>' + attribute.value + '</strong>';
          });
        }
        return TableItemLabeledValue.of(this.productId, stringToPresent);
      }
      default:
        response = super.getItemValueByProperty(propertyName);
    }
    if (!response) {
      const value = this[propertyName];
      if (!value) {
        response = TableItemLabeledValue.empty();
      }
      response = TableItemLabeledValue.of(value);
    }
    return response;
  }
  public getItemClassByProperty(propertyName: string): string {
    return '';
  }
}
