import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { Meta } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject, LOCALE_ID, Optional } from '@angular/core';
import { Constants } from '../../common/constants';
import { PlatformStateService } from './platform-state.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { ThemeStorageService, SiteTheme } from './theme-storage.service';
import { EntityToMedia } from '../../models/entity-to-media';
import { Company, User } from '@pdp/common';
import { takeUntil } from 'rxjs/operators';
import { UserStateService } from '@shop/app/providers/services/user-state.service';
import { PlatformAwareService } from './platform-aware.service';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { RequestUtils } from '@shop/app/common/request-utils';
import { Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends PlatformStateService<Company> {

  public baseCssUrl = environment.baseUrlApi + '/css/';

  protected getBaseUrl(): string {
    return Constants.COMPANY;
  }

  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) protected localeId: string,
    private platformAwareService: PlatformAwareService,
    @Optional() @Inject(REQUEST) private request: Request,
    private httpClient: HttpClient,
    private userStateService: UserStateService,
    // private authService: AuthService,
    // private userService: UserService,
    // private platformHttpService: PlatformHttpService,
    private meta: Meta,
    private themeStorageService: ThemeStorageService,
    private rendererFactory: RendererFactory2) {
    super();
    this.renderer = this.rendererFactory.createRenderer(null, {
      id: 'plausible-script-renderer',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  private loadStyle(clientStyles: string) {
    if (this.platformAwareService.isBrowser) {
      var head = document.head || document.getElementsByTagName('head')[0];
      var style: HTMLStyleElement = document.createElement('style');
      head.appendChild(style);
      style.type = 'text/css';
      // style.setAttribute('rel','preload')
      style.appendChild(document.createTextNode(clientStyles));
    }
  }

  private loadPlausibleScript(domain: string) {
    try {
      RequestUtils.logRequest('Loading Plausible script for domain ', { domain });

      // Create script element using renderer
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'defer', 'true');
      this.renderer.setAttribute(script, 'data-domain', domain);
      this.renderer.setAttribute(script, 'src', 'https://plausible.io/js/script.js');

      if (this.platformAwareService.isBrowser) {
        // Browser-specific logic
        const head = this.document.head;
        if (!head) {
          console.warn('Head element not found');
          return;
        }

        // Check if script already exists
        const existingScript = this.document.querySelector(`script[data-domain="${domain}"]`);
        if (existingScript) {
          console.log('Plausible script already exists');
          return;
        }

        this.renderer.appendChild(head, script);
      } else {
        // Server-side specific logic
        if (this.document) {
          const head = this.document.getElementsByTagName('head')[0];
          if (!head) {
            console.warn('Head element not found on server');
            return;
          }
          this.renderer.appendChild(head, script);
        } else {
          console.warn('Document not found on server');
        }
      }
    } catch (error) {
      console.error('Error loading Plausible script:', error);
    }
  }

  public initApp(config: NgcCookieConsentConfig): Promise<any> {
    RequestUtils.logRequest('CompanyService initApp');

    let headers = new HttpHeaders();
    RequestUtils.logRequest('InitApp ', { isServer: this.platformAwareService.isServer });
    if (this.platformAwareService.isServer) {
      headers = headers.append('x-host', this.request.hostname);
    }
    headers = headers.append('X-PLATFORM-REQUEST', environment.client);

    const requestOptions = {
      observe: 'body' as 'body',
      headers: headers,
      withCredentials: true
    };
    const promise = this.httpClient.request('GET', environment.baseUrlApi + this.getBaseUrl() + '/owner', requestOptions)
      .toPromise()
      .then((ownerCompany: Company) => {
        this.initAppInternal(ownerCompany, config);
      });
    return promise;
  }

  private initAppInternal(ownerCompany: Company, config: NgcCookieConsentConfig) {
    this.setValue = ownerCompany;
    this.fetchUserIfLoggedIn(ownerCompany);
    // Add Plausible analytics
    if (ownerCompany.domain && ownerCompany.plausibleAnalyticsEnabled) {
      this.loadPlausibleScript(ownerCompany.domain);
    }

    // cookie consent
    config.cookie.domain = ownerCompany.domain;
    this.setFavicon(ownerCompany);
    if (ownerCompany && ownerCompany.allCompanySettings) {
      // this.jsonLdService.setHomePageCompanyJsonLd(ownerCompany.allCompanySettings.homePageJsonLdSchema);
      if (ownerCompany.allCompanySettings.shopColorTheme) {
        this.themeStorageService.selectTheme(ownerCompany.allCompanySettings.shopColorTheme)
        this.themeStorageService.getStoredThemeName()
          .pipe(takeUntil(this.unsubscribe)).subscribe((siteTheme: SiteTheme) => {
            RequestUtils.logRequest('Stored theme', { siteTheme });
            const theme: SiteTheme = this.themeStorageService.findThemeByName(siteTheme.name);
            RequestUtils.logRequest('Selected theme', { theme });
            const primaryColor = theme.primaryColor500;
            this.meta.removeTag("name='theme-color'");
            this.meta.addTags([
              { name: 'theme-color', primaryColor }
            ]);

            if (ownerCompany.allCompanySettings.clientStyleCss) {
              this.loadStyle(ownerCompany.allCompanySettings.clientStyleCss);
            }
          })
      }
    }
    return ownerCompany;
  }

  private fetchUserIfLoggedIn(company: Company) {
    RequestUtils.logRequest('Fetching user for ', { uti: company.uti });
    let headers = new HttpHeaders();
    headers = headers.append('X-PLATFORM-REQUEST', environment.client);
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('X-PLATFORM-UTI', company.uti);
    headers = headers.append('x-platform-ml', true + '');
    // browser automatically sets Accept-Language to system configured settings. We cannot control language fix to serbian, if desktop keyboard is configured to EN (just an example)
    headers = headers.append('x-platform-accept-language', this.localeId);

    if (this.platformAwareService.isServer) {
      headers = headers.append('x-host', this.request.hostname);
    }

    const requestOptions = {
      observe: 'body' as 'body',
      headers: headers,
      withCredentials: true
    };
    const promise = this.httpClient.request('GET', company.apiBaseUrl + '/pdp/userinfo', requestOptions)
      .pipe(takeUntil(this.unsubscribe)).subscribe((user: User) => {
        RequestUtils.logRequest('User info', { user });
        if (user && 'Anonymous' != user['firstLastName']) {
          RequestUtils.logRequest('User is logged in', { user });
          // this.userStateService.setCurrentUser(user)
          // this.userStateService._loggedIn.next(LoggedInOrResetPassword.createTrue())
        } else {
          RequestUtils.logRequest('User is not logged in');
          // this.userStateService._loggedIn.next(LoggedInOrResetPassword.createFalse())
        }
        this.userStateService.setCurrentUser(user);
      });
    return promise;
  }

  private setFavicon(company: Company) {
    if (this.platformAwareService.isBrowser) {
      // https://stackoverflow.com/questions/48956465/favicon-standard-2020-svg-ico-png-and-dimensions
      // https://stackoverflow.com/questions/19029342/favicons-best-practices/45301651#45301651
      if (company && company.mediasMap) {
        const favicon16x16EntityToMedia: EntityToMedia = company.mediasMap['FAVICON_16_X_16'];
        const favicon32x32EntityToMedia: EntityToMedia = company.mediasMap['FAVICON_32_X_32'];
        const favicon180x180TouchAppleHomeEntityToMedia: EntityToMedia = company.mediasMap['FAVICON_APPLE_TOUCH_180_X_180'];
        const favicon192x192AndroidHomeEntityToMedia: EntityToMedia = company.mediasMap['FAVICON_ANDROID_192_X_192'];

        if (favicon32x32EntityToMedia && favicon32x32EntityToMedia.media && favicon32x32EntityToMedia.media.url) {
          let favIcon: HTMLLinkElement = document.querySelector('#favicon32x32Icon');
          favIcon.href = favicon32x32EntityToMedia.media.url;
        }
        if (favicon16x16EntityToMedia && favicon16x16EntityToMedia.media && favicon16x16EntityToMedia.media.url) {
          let favIcon: HTMLLinkElement = document.querySelector('#favicon16x16Icon');
          favIcon.href = favicon16x16EntityToMedia.media.url;
        }
        if (favicon180x180TouchAppleHomeEntityToMedia && favicon180x180TouchAppleHomeEntityToMedia.media && favicon180x180TouchAppleHomeEntityToMedia.media.url) {
          let favIcon: HTMLLinkElement = document.querySelector('#appleTouchfavicon180x180Icon');
          favIcon.href = favicon180x180TouchAppleHomeEntityToMedia.media.url;
        }
        if (favicon192x192AndroidHomeEntityToMedia && favicon192x192AndroidHomeEntityToMedia.media && favicon192x192AndroidHomeEntityToMedia.media.url) {
          let favIcon: HTMLLinkElement = document.querySelector('#androidfavicon192x192Icon');
          favIcon.href = favicon192x192AndroidHomeEntityToMedia.media.url;
        }
      }
    }

  }
}
