import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Component, OnInit, Input, Inject, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';

@Component({
  selector: 'pdp-slider-two',
  templateUrl: './slider-two.component.html',
  styleUrls: ['./slider-two.component.scss']
})
export class SliderTwoComponent implements OnInit {

  private environment: any;

  public items: ItemToCategoryToComponent[];

  @Input('items')
  public set setItems(items: ItemToCategoryToComponent[]) {
    // for (let current of items) {
    //   if (current && current.media) {
    //     current.media.url = this.mediaKeyQueryParamAwarePipe.transform(this.environment.baseUrl + '/images/category-components/' + current.media.url);
    //   }
    // }
    this.items = items;
  }

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(public router: Router,
    @Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void { }

  public navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  // Slick slider config
  public sliderConfig: any = {
    autoplay: false,
    autoplaySpeed: 3000,
    // dots: true,
    infinite: true
  };
}

// Svaka komponenta koja se dinamicki ucitava mora da ima svoj privatni modul da bi se importovali dependency poput
@NgModule({
  imports: [
    CommonModule,
    SlickCarouselModule,
  ],
  declarations: [
    SliderTwoComponent
  ],
  providers:[]
})
class SliderTwoModule {}