import { DateWithNoDatePipe } from './date_with_no_date_pipe';
import { NotANumberPipe } from './not-a-number.pipe';
import { NgModule } from '@angular/core';
import { UtiQueryParamAwarePipe } from './uti-query-param-aware.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { PdpCapitalizeFirstPipe } from './pdp-capitalize-first.pipe';

@NgModule({
  declarations: [
    PdpCapitalizeFirstPipe,
    SanitizeHtmlPipe,
    UtiQueryParamAwarePipe,
    NotANumberPipe,
    DateWithNoDatePipe
  ],
  imports: [
  ],
  exports: [
    PdpCapitalizeFirstPipe,
    SanitizeHtmlPipe,
    UtiQueryParamAwarePipe,
    NotANumberPipe,
    DateWithNoDatePipe
  ]
})
export class PipesModule { }
