import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { Company } from '@pdp/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {

  private unsubscribe: Subject<void> = new Subject();

  private scriptType = 'application/json+ld';
  private homePageJsonLdSchema: string;
  private className: string = 'home-json-ld';
  //https://medium.com/javascript-in-plain-english/how-to-use-json-ld-for-advanced-seo-in-angular-63528c98bb91
  constructor(@Inject(DOCUMENT) private _document: Document, public router: Router,
    private companyService: CompanyService) {

    this.companyService.getValue.pipe(takeUntil(this.unsubscribe)).subscribe((company: Company) => {
      if (company && company.allCompanySettings.homePageJsonLdSchema) {
        this.homePageJsonLdSchema = company.allCompanySettings.homePageJsonLdSchema;
        this.initiateHomePageJsonLdSchema();
      }
    })
  }

  /**
   * General jsonLd such as organization or website jsonLd should only appear in one page (usually home or about us page.)
   * Here we add it to home page and remove it immediatelly after customer has left the home page.
   */
  private initiateHomePageJsonLdSchema() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        if (url !== '/') {
          this.removeHomePageCompanyJsonLd();
        } else {
          this.setHomePageCompanyJsonLd(this.homePageJsonLdSchema);
        }
      }
    })
  }

  public setHomePageCompanyJsonLd(homePageJsonLdSchema: string) {
    this.homePageJsonLdSchema = homePageJsonLdSchema;
    if (!homePageJsonLdSchema) {
      return;
    }
    let script: any;
    const elements: HTMLCollectionOf<Element> = this._document.head.getElementsByClassName(this.className);
    if (elements.length) {
      script = this._document.head.getElementsByClassName(this.className)[0];
    }
    // check if we already have jsonLd. If not add it
    if (!script) {
      script = this._document.createElement('script');
      script.type = this.scriptType;
      script.text = this.homePageJsonLdSchema;
      script.setAttribute('class', 'home-json-ld');
      this._document.head.appendChild(script);
    }
  }

  public removeHomePageCompanyJsonLd() {
    const elements: HTMLCollectionOf<Element> = this._document.head.getElementsByClassName(this.className);
    if (elements.length) {
      let script = this._document.head.getElementsByClassName(this.className)[0];
      this._document.head.removeChild(script);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
