import { PipesModule } from '@pdp/pipes';
import { CommonModule } from '@angular/common';
import { ResourceType } from './../../models/resource-type';
import { Component, Input, Inject, NgModule } from '@angular/core';
import { ComponentType } from '../component-type';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';
import { Company } from '../../models/company';
import { PlatformStateService } from '../../providers/platform-state.service';

@Component({
  selector: 'pdp-one-column-text-dynamic',
  templateUrl: './one-column-text-dynamic.component.html',
  styleUrls: ['./one-column-text-dynamic.component.scss']
})
export class OneColumnTextDynamicComponent {

  private environment: any;
  public companyService: PlatformStateService<Company>;
  @Input()
  public items: ItemToCategoryToComponent[];
  @Input()
  public componentType: ComponentType;
  @Input()
  public parentToComponentId: number;
  @Input()
  public parentId: number;
  @Input()
  public resourceType: ResourceType;

  // set in shop or offer appModule as provider.
  constructor(@Inject('currentEnvironment') environment: any,
              @Inject('currentCompanyService') companyService: PlatformStateService<Company>) {
      this.environment = environment;
      this.companyService = companyService;
  }
}

@NgModule({
  imports: [
    CommonModule,
    PipesModule
  ],
  declarations: [
    OneColumnTextDynamicComponent
  ],
  providers:[]
})
class OneColumnTextModule {}