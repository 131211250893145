import { ContentType } from './content-type';
import { TableItemContent } from './table-item-content';

export class Content {
    public contentType: ContentType;
    public content: TableItemContent;
    public isDisabled: boolean;

    constructor(content: TableItemContent = TableItemContent.empty() , contentType: ContentType = ContentType.TEXT, isDisabled = false) {
        this.isDisabled = isDisabled;
        this.contentType = contentType;
        this.content = content;
    }
    public static newLink(url: string): Content {
        return new Content(TableItemContent.of(url), ContentType.LINK);
    }
    // query params to be provided within each TableItemLabeledValue. Example in StockOverview
    public static newLinkWithQueryParams(url: string): Content {
        return new Content(TableItemContent.of(url, null, null), ContentType.LINK);
    }
    public static newLinkExternal(url: string): Content {
        return new Content(TableItemContent.of(url), ContentType.LINK_EXTERNAL);
    }
    public static newMatInput(isDisabled: boolean = false): Content {
        return new Content(null, ContentType.MAT_INPUT, isDisabled);
    }
    public static newMatInputText(isDisabled: boolean = false): Content {
        return new Content(null, ContentType.MAT_INPUT_TEXT, isDisabled);
    }
    public static newMatSelect(content: TableItemContent, isDisabled: boolean = false): Content {
        return new Content(content, ContentType.MAT_SELECT, isDisabled);
    }
    public static newMatIcon(): Content {
        return new Content(null, ContentType.MAT_ICON);
    }
    public static newMatIconWithHover(): Content {
        return new Content(null, ContentType.MAT_ICON_WITH_HOVER);
    }
    public static newMatIconWithoutHover(): Content {
        return new Content(null, ContentType.MAT_ICON_WITHOUT_HOVER);
    }
    public static newTextWithPrefix(): Content {
        return new Content(null, ContentType.TEXT_WITH_PREFIX);
    }

    public static newTextWithOutput(): Content {
        return new Content(null, ContentType.TEXT_WITH_OUTPUT);
    }
    
    public static newPrice(): Content {
        return new Content(null, ContentType.PRICE);
    }
    public static newDate(): Content {
        return new Content(null, ContentType.DATE);
    }
    public static newDateTime(): Content {
        return new Content(null, ContentType.DATE_TIME);
    }
    public static newDateTimeSeconds(): Content {
        return new Content(null, ContentType.DATE_TIME_SECONDS);
    }
    public static newDateTimeMilis(): Content {
        return new Content(null, ContentType.DATE_TIME_MILIS);
    }
    public static newImage(): Content {
        return new Content(null, ContentType.MEDIA);
    }
    public static newMatCheckbox(isDisabled: boolean = false): Content {
        return new Content(null, ContentType.MAT_CHECKBOX, isDisabled);
    }
    public static newMatCheckboxWithFulfilment(isDisabled: boolean = false): Content {
        return new Content(null, ContentType.MAT_CHECKBOX_WITH_FULFILMENT_COG, isDisabled);
    }
    public static newMatCheckboxWithFulfilmentInvoice(): Content {
        return new Content(null, ContentType.MAT_CHECKBOX_WITH_FULFILMENT_INVOICE_COG);
    }
    public static newButton(content: TableItemContent): Content {
        return new Content(content, ContentType.BUTTON);
    }
    public static newMultiButton(content: TableItemContent): Content {
        return new Content(content, ContentType.MULTI_BUTTON);
    }
    public static newDeleteButton(isDisabled: boolean = false): Content {
        return new Content(null, ContentType.MAT_DELETE_BUTTON, isDisabled);
    }
    public static newCustomForEachItem(isDisabled: boolean = false): Content {
        return new Content(null, ContentType.CUSTOM_FOR_EACH_ITEM, isDisabled);
    }
}