import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformHttpService } from '../common/http/service/platformhttp.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserStateService } from '../providers/services/user-state.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: ``
})
export class AuthorizeComponent implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject();

  constructor(private platformHttpService: PlatformHttpService, 
              private userStateService: UserStateService,
              private router: Router) { }

  // this is where we land after succesfully authenticated at the IDP server
  ngOnInit(): void {
    // console.log('Completing authentication....')
    // this.router.navigate(['/home'])
    this.platformHttpService.get(`/account/userinfo`)
      .pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
        console.log('Completed authentication....')
        console.log(user)
        this.userStateService.setCurrentUser(user);
        this.router.navigate(['/home'])
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
