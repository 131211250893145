import { SearchResultsComponent } from './search-results/search-results.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BarRatingModule } from 'ngx-bar-rating';
import { MaterialModule } from '../material/material.module';
import { IconModule } from '../../scss/icon/icon.module';

import { SaniteizeImagePipe } from './pipes/saniteize-image.pipe';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { SearchComponent } from './search/search.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductListHeaderComponent } from './components/product-list/header/product-list-header.component';
import { ProductListItemComponent } from './components/product-list/item/product-list-item.component';
import { ProductSliderHorizontalComponent } from './components/product-slider-horizontal/product-slider-horizontal.component';
import { AddAddressComponent } from './components/address/add-address/add-address.component';
import { AddressBookComponent } from './components/address/address-book/address-book.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductListFooterComponent } from './components/product-list/footer/product-list-footer.component';
import { ProductListItemThreeComponent } from './components/product-list/product-list-item-three/product-list-item-three.component';
import { ItemTwoComponent } from './components/product-list/product-list-item-two/item-two.component';
import { ServicesTwoComponent } from './components/two/services-two/services-two.component';
import { ProductBoxTwoComponent } from './components/two/product-box-two/product-box-two.component';
import { ProductBoxVerticalTwoComponent } from './components/two/product-box-vertical-two/product-box-vertical-two.component';
import { QuickViewComponent } from './components/two/quick-view/quick-view.component';
import { ProductSliderTwoComponent } from './components/dynamic/product-slider-two/product-slider-two.component';
import { SpecialProductsTwoComponent } from './components/dynamic/special-products-two/special-products-two.component';
import { FromTheBlogComponent } from '@shop/app/modules/shared/components/dynamic/from-the-blog/from-the-blog.component';
import { SearchTwoComponent } from './search-two/search-two.component';
import { ThreeImagesDynamicComponent } from './components/dynamic/three-images-dynamic/three-images-dynamic.component';
import { ThreeImagesFullWidthDynamicComponent } from './components/dynamic/three-images-full-width-dynamic/three-images-full-width-dynamic.component';
import { CustomSkeletonLoaderModule } from './components/custom-skeleton-loader/custom-skeleton-loader.module';
import { InfoSectionModule } from './components/info-section/info-section.module';
import { BrandListModule } from './components/expo/expo-brand-list/expo-brand-list.module';
import { ExpoStoryComponent } from './components/expo/expo-story/expo-story.component';
import { TotalPriceShopComponent } from './total-price-shop/total-price-shop.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SlickCarouselModule,
        BarRatingModule,
        IconModule,
    ],
    declarations: [
        SidebarComponent,
        SanitizeHtmlPipe,
        CapitalizeFirstPipe,
        SearchComponent,
        ProductListItemComponent,
        ProductListHeaderComponent,
        ProductListComponent,
        ProductSliderHorizontalComponent,
        AddAddressComponent,
        AddressBookComponent,
        SaniteizeImagePipe,
        BreadcrumbComponent,
        ProductListFooterComponent,
        ItemTwoComponent,
        ProductListItemThreeComponent,
        FromTheBlogComponent,
        ServicesTwoComponent,
        ProductSliderTwoComponent,
        SpecialProductsTwoComponent,
        ProductBoxTwoComponent,
        ProductBoxVerticalTwoComponent,
        QuickViewComponent,
        SearchTwoComponent,
        ThreeImagesDynamicComponent,
        ThreeImagesFullWidthDynamicComponent,
        SearchResultsComponent,
        ExpoStoryComponent,
        TotalPriceShopComponent,
    ],
    exports: [
        SidebarComponent,
        SanitizeHtmlPipe,
        SaniteizeImagePipe,
        CapitalizeFirstPipe,
        SearchComponent,
        ProductSliderHorizontalComponent,
        ProductListHeaderComponent,
        ProductListFooterComponent,
        ProductListComponent,
        AddAddressComponent,
        AddressBookComponent,
        BreadcrumbComponent,
        FromTheBlogComponent,
        ServicesTwoComponent,
        ProductSliderTwoComponent,
        SpecialProductsTwoComponent,
        ProductBoxTwoComponent,
        ProductBoxVerticalTwoComponent,
        SearchTwoComponent,
        CustomSkeletonLoaderModule,
        SearchResultsComponent,
        InfoSectionModule,
        BrandListModule,
        ExpoStoryComponent,
        TotalPriceShopComponent
    ]
})
export class SharedModule {}
