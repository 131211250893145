import { Params } from '@angular/router';
import { Button, ButtonState } from './button';

export class TableItemContent {

    constructor(public content: string = null,
        public getButtonState: (button: any) => ButtonState = null,
        public buttons: Button[] = null,
        public queryParams: Params = null,
        public selectValues?: any[]) { }

    public static of(contentToSet: string): TableItemContent;

    public static of(contentToSet: string,
        getButtonState: (button: Button) => ButtonState,
        buttons: Button[]): TableItemContent;

    public static of(contentToSet: string,
        getButtonState?: (button: Button) => ButtonState,
        buttons?: Button[]): TableItemContent;

    public static of(contentToSet: string,
        getButtonState?: (button: Button) => ButtonState,
        buttons?: Button[],
        queryParams?: Params): TableItemContent;

    public static of(contentToSet: string,
        getButtonState?: (button: Button) => ButtonState,
        buttons?: Button[],
        queryParams?: Params,
        selectValues?: any[]): TableItemContent;

    public static of(contentToSet: string,
        getButtonState?: (button: Button) => ButtonState,
        buttons?: Button[],
        queryParams?: Params,
        selectValues?: any[]): TableItemContent {
        return new TableItemContent(contentToSet, getButtonState, buttons, queryParams, selectValues);
    }

    public static empty(): TableItemContent {
        return new TableItemContent();
    }
}
