import { ItemToCategoryToComponent } from './../../models/item-to-category-to-component';
import { ComponentType } from './../component-type';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Inject, NgModule } from '@angular/core';
import { PipesModule } from '@pdp/pipes';



@Component({
  selector: 'pdp-dynamic-alert-message',
  templateUrl: './dynamic-alert-message.component.html',
  styleUrls: ['./dynamic-alert-message.component.scss']
})
export class DynamicAlertMessageComponent implements OnInit {

  public dynamicCss = {};

  private environment: any;
  @Input()
  public componentType: ComponentType;

  public singleItem: ItemToCategoryToComponent;

  @Input('items')
  public set setItems(items: ItemToCategoryToComponent[]) {
    if (items && items[0]) {
      this.singleItem = items[0];
      this.dynamicCss['border-left'] = '4px solid ' + this.adjust(this.singleItem.contentBackgroundColor, -35);
    }
  }

  private adjust(color: string, amount: number) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  constructor(
    @Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void { }
}

@NgModule({
  imports: [
    CommonModule,
    PipesModule
  ],
  declarations: [
    DynamicAlertMessageComponent
  ],
  providers: []
})
class AlertMessageDynamicModule { }

