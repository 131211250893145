<div class="expansion-panel-dynamic">
    <h2 class="expansion-panel-dynamic--title">
      Frequently asked questions
    </h2>
    <mat-accordion>
        <mat-expansion-panel *ngFor="let item of items" [expanded]="false" class="expansion-panel-dynamic--item">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{item?.leftContent}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <span [innerHtml]="item?.rightContent | sanitizeHtml: 'HTML' "> </span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
</div>
