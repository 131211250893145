import { DOCUMENT } from '@angular/common';
import { PlatformAwareService } from './platform-aware.service';
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';

/**
 * Class for managing stylesheets. Stylesheets are loaded into named slots so that they can be
 * removed or changed later.
 */
@Injectable({
  providedIn: 'root'
})
export class StyleManagerService {

  private renderer: Renderer2;
  constructor(private platformAwareService: PlatformAwareService,
    @Inject(DOCUMENT) private _document: any,
    private rendererFactory: RendererFactory2){
        this.renderer = this.rendererFactory.createRenderer(this._document, {
          id: 'alternate_link_theme_storage_server_injector',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
        });
    }

  /**
   * Set the stylesheet with the specified key.
   */
  public setStyle(key: string, href: string) {
    this.getLinkElementForKey(key).setAttribute('href', href);
  }

  /**
   * Remove the stylesheet with the specified key.
   */
  public removeStyle(key: string) {
    const existingLinkElement = this.getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      this._document.head.removeChild(existingLinkElement);
    }
  }

  public getLinkElementForKey(key: string) {
    return this.getExistingLinkElementByKey(key) || this.createLinkElementWithKey(key);
  }
  
  public getExistingLinkElementByKey(key: string) {
    return this._document.head.querySelector(`link[rel="stylesheet"].${this.getClassNameForKey(key)}`);
  }
  
  public createLinkElementWithKey(key: string) {
    const scriptElt = this.renderer.createElement('link');
    this.renderer.setAttribute(scriptElt, 'rel', 'stylesheet');
    this.renderer.setAttribute(scriptElt, 'type', 'text/css');
    this.renderer.addClass(scriptElt, this.getClassNameForKey(key));
    this.renderer.appendChild(this._document.head, scriptElt);
    return scriptElt;
  }
  
  public getClassNameForKey(key: string) {
    return `style-manager-${key}`;
  }
}

