import { PipesModule } from '@pdp/pipes';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, SimpleChanges, Inject, NgModule } from '@angular/core';
import { ItemToCategoryToComponent } from '../../models/item-to-category-to-component';
import { Router } from '@angular/router';
import { ResourceType } from '../../models/resource-type';

@Component({
  selector: 'pdp-image-left-text-right-shop-dynamic',
  templateUrl: './image-left-text-right-shop-dynamic.component.html',
  styleUrls: ['./image-left-text-right-shop-dynamic.component.scss']
})
export class ImageLeftTextRightShopDynamicComponent implements OnInit {

  private environment: any;
  public baseImageUrl: string;
  @Input()
  public resourceType: ResourceType;

  @Input()
  public items: ItemToCategoryToComponent[];
  public singleItem: ItemToCategoryToComponent;

  // set currentEnvironment in shop or offer appModule as provider.
  constructor(public router: Router,
    @Inject('currentEnvironment') environment) {
    this.environment = environment;
  }

  ngOnInit(): void { }

  public navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceType']) {
      if (this.items && this.items[0]) {
        this.singleItem = this.items[0];
      }
    }
  }
}


@NgModule({
  imports: [
    CommonModule,
    PipesModule
  ],
  declarations: [
    ImageLeftTextRightShopDynamicComponent
  ],
  providers:[]
})
class ImageLeftTextRightModule {}