import { ContentType, OrderItem, TableItemLabeledValue } from '@pdp/common';
import { LocalizedColumnFactory } from '@pdp/localization';


export class ShopOrderItem extends OrderItem {

    public getItemValueByProperty(propertyName: string): TableItemLabeledValue {

        switch (propertyName) {
            case 'name': {
                return TableItemLabeledValue.of(this.url, this.name, ContentType.LINK, { 'itm': this.productId });
            }
            case 'removeItem':
                // remove is always enabled to shop. This is used in cart or checkout page
                return TableItemLabeledValue.of(false, LocalizedColumnFactory.removeItem_().currentLocaleColumnName);
            default:
                return super.getItemValueByProperty(propertyName);
        }
    }
}