import { Component, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from '../../providers/services/spinner.service';

@Component({
  selector: 'pdp-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  public loading: boolean;

  constructor(public spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef) {
  }

  private showSpinner(show: boolean) {
    if (show) {
      // happend few times that spinner gets stuck and user is unable to continue, even though requests have all been resolved.
      // show spinner for 5 seconds maksimum. First set to true, then set timer to automatically set loading to false
      this.loading = true;
      // setTimeout(() => {
        this.loading = false;
        this.cdr.detectChanges();
      // }, 5000)
    } else {
      this.loading = false;
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit() {
    this.spinnerService.isLoading.subscribe((show) => {
      this.showSpinner(show);
    });
    this.cdr.detectChanges();
  }

}
