import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CoreModule } from './core/core.module';
import { IconModule } from './scss/icon/icon.module';
import { SharedModule } from './modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from './../environments/environment';
import { NgModule, ErrorHandler, APP_INITIALIZER, Inject, PLATFORM_ID, APP_ID } from '@angular/core';
import localeSrLatn from '@angular/common/locales/sr-Latn';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
// Services
import { PlatformHttpService } from './common/http/service/platformhttp.service';

// Security
import { AuthService } from './common/auth/service/auth.service';
import { LoggedInGuard } from './common/auth/guards/loggedin.guard';

// Components
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformErrorHandler } from './common/PlatformErrorHandler';
import { SitemapComponent } from './modules/sitemap/sitemap.component';
import { PlatformRequestInterceptor } from './providers/interceptors/platform-request.interceptor';
import { CompanyService } from './providers/services/company.service';
import { SpinnerInterceptor } from './providers/interceptors/spinner.interceptor';
import { GoogleAnalyticsService } from './providers/services/google-analytics.service';
import { JsonLdService } from './providers/services/json-ld.service';
import { GatewayInterceptor } from './providers/interceptors/gateway.interceptor';
import { XsrfInterceptor } from './providers/interceptors/xsrf.interceptor';
import { AuthorizeComponent } from './authorize/authorize.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RequestUtils } from './common/request-utils';

registerLocaleData(localeSrLatn);

export function appInit(companyService: CompanyService, 
  config: NgcCookieConsentConfig) {
    RequestUtils.logRequest('App inititializing...');
  return () => companyService.initApp(config);
}

// https://github.com/tinesoft/ngx-cookieconsent/issues/41
let cookieConsentConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'not-set'
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#f5f8fd",
      "text": "#6f8ba4",
      "link": "#3b566e"
    },
    "button": {
      "background": "rgb(241, 239, 255)",
      "text": "rgb(51, 122, 183)",
      "border": "transparent"
    }
  },
  type: 'info',
  revokeBtn: '<div class="cc-revoke {{classes}}">Prikaži opcije kolačića</div>',
  revokable: false,
  showLink: true,
  // dismissOnScroll: 20,
  dismissOnTimeout: 30,
  content: {
    message: 'Ova stranica koristi kolačiće, zbog unapređenja Vašeg korisničkog iskustva. Nastavkom istraživanja stranice, prihvatate upotrebu kolačića.',
    dismiss: 'Prihvatam!',
    deny: 'Odbijam',
    allow: 'Prihvatam',
    link: 'Saznajte više',
    href: '/info/privacy-policy',
  }
};

// This is the only way i could find to reuse same instance of AppInitService in libraries.
// We dont want multiple instances because of duplicate http requests
export function getCompanyServiceFactory(companyService: CompanyService): CompanyService {
  return companyService;
}

@NgModule({
  declarations: [
    AppComponent,
    SitemapComponent,
    AuthorizeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,  // needed for toastr
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    HttpClientModule,
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConsentConfig),
    SharedModule,
    SlickCarouselModule,
    IconModule,
    CoreModule
  ],
  providers: [
    PlatformHttpService,
    AuthService,
    LoggedInGuard,
    { provide: ErrorHandler, useClass: PlatformErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: PlatformRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GatewayInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInit, multi: true, deps: [CompanyService, NgcCookieConsentConfig] },
    { provide: APP_ID,  useValue: 'shopServerApp' },
    { provide: 'currentEnvironment', useValue: environment },
    { provide: 'currentCompanyService', useFactory: getCompanyServiceFactory, deps: [CompanyService]}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // We inject the service here to keep it alive whole time
  constructor(@Inject(PLATFORM_ID) public platformId: string,
    public _googleAnalyticsService: GoogleAnalyticsService,
    public jsonLdService: JsonLdService) { }
}
