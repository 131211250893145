
import { throwError as observableThrowError } from 'rxjs';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { RequestUtils } from './request-utils';

@Injectable({
  providedIn: 'root'
})
export class PlatformErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }
  // https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c
  // https://dpopescu.me/2016/10/21/catching-global-errors-in-angular-2/

  handleError(error) {
    console.log('Error handler', error);  
    // RequestUtils.logRequest('Error handler', error);

    // const message = error.message ? error.message : error.toString();
    // const loggingService = this.injector.get(LoggerService);
    // log on the server
    //   loggingService.log({ message });
    // });
    return observableThrowError(error);

  }

}
