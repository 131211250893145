<ng-container *ngIf="items && singleItem && singleItem.media && singleItem.media.url && resourceType">
    <div class="single-image-dynamic" [ngClass]="{'full-width' : singleItem?.mediaFullWidth}">
        <a *ngIf="singleItem.url" routerLink="{{singleItem.url}}">
            <img [src]="singleItem.media.url"
                [alt]="singleItem.media?.altText ? singleItem.media.altText : ''"
                [title]="singleItem.media?.title ? singleItem.media.title : ''">
        </a>

        <img *ngIf="!singleItem.url" [src]="singleItem.media.url"
            [alt]="singleItem.media?.altText ? singleItem.media.altText : ''"
            [title]="singleItem.media?.title ? singleItem.media.title : ''">
    </div>
</ng-container>
