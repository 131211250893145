import { Injectable } from '@angular/core';
import {  HttpParams } from '@angular/common/http';

import { Constants } from '../../common/constants';

import {Observable, BehaviorSubject, Subject} from 'rxjs';
import { Customer } from '../../models/customer';
import { PlatformHttpService } from '../../common/http/service/platformhttp.service';

@Injectable({
    providedIn: 'root'
  })
export class UserService {

  // category subject & observable
  private _customer: Subject<any> = new BehaviorSubject([]);
  public customer: Observable<Customer> = this._customer.asObservable();

  constructor(public platformhttpservice: PlatformHttpService) {}



    public create(customer: Customer) {
         return this.platformhttpservice.post(Constants.CUSTOMER, customer);
    }
    public createGuest(customer: Customer) {
        return this.platformhttpservice.post(Constants.CUSTOMER+'/guest', customer);
   }
    

     public getCustomerInfo(): Observable<any> {
        return this.platformhttpservice.get(Constants.CUSTOMER);
    }

    public update(user: any) {
        return this.platformhttpservice.put(Constants.CUSTOMER + '/' + user.id, user);
    }

    /**
     * Regular change password - Account profile page
     * @param passwordChangeRequest
     */
    public changePassword(passwordChangeRequest: any): Observable<any> {
        return this.platformhttpservice.post(Constants.CUSTOMER + Constants.HD_JDL, passwordChangeRequest);
    }

     /**
     * Forgot password change password - forgot password
     * @param passwordChangeRequest
     */
    public changePasswordForgotPasswordFlow(passwordChangeRequest: any, userId, token): Observable<any> {

        let params = new HttpParams();
        params = params.append('customerId', userId);
        params = params.append('token', token);

        return this.platformhttpservice.post(Constants.CUSTOMER + Constants.RESET_PASSWORD, passwordChangeRequest, params, true);
    }

    public newsletterSubscribe(value) {
        const requestBody = { email : value };
        return this.platformhttpservice.post(Constants.CUSTOMER + Constants.PROFILE + Constants.NEWSLETTER, requestBody);
    }
    
    public verifyPasswordToken(userId, token) {
        let params = new HttpParams();
        params = params.append('customerId', userId);
        params = params.append('token', token);
        return this.platformhttpservice.get(Constants.CUSTOMER + Constants.VERIFY_PASSWORD_TOKEN, params);
    }

}
