<section class="media-slider-two">
    <ngx-slick-carousel class="home-slider" #slickModal="slick-carousel" [config]="sliderConfig">
        <div ngxSlickItem *ngFor="let item of items">
            <div class="home home39 text-center " 
            [style.background-image]="'url(' + item?.media?.url + ')'">
                <div class="media-slider-two--content slider-contain">
                    <div>
                        <h1>{{item?.largeTitle}}</h1>
                        <h4>{{item?.smallTitle}}</h4>
                        <span *ngIf="item?.buttonTex" (click)="navigateTo(item?.url)" class="btn border-button">{{item?.buttonText}}</span>
                    </div>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</section>