// import { PlatformAwareService } from './platform-aware.service';
import { Injectable, EventEmitter, Inject, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { StyleManagerService } from './style-manager.service';
import { VERSION } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { PlatformAwareService } from './platform-aware.service';
import { PlatformStateService } from './platform-state.service';
import { Observable } from 'rxjs';
import { RequestUtils } from '@shop/app/common/request-utils';

export interface SiteTheme {
  id: number,
  name: string;
  displayName?: string;
  isDefault?: boolean;
  primaryColor500?: string
}

@Injectable({
  providedIn: 'root'
})
export class ThemeStorageService extends PlatformStateService<SiteTheme>{

  private renderer: Renderer2;
  constructor(public styleManager: StyleManagerService,
    @Inject(DOCUMENT) private _document: any,
    private platformAwareService: PlatformAwareService,
    private rendererFactory: RendererFactory2,
    private liveAnnouncer: LiveAnnouncer) {
      super();
  }
  currentTheme: SiteTheme;

  // The below colors need to align with the themes defined in theme-picker.scss
  public themes: SiteTheme[] = [
    {
      id: 1,
      displayName: 'Light-blue',
      name: 'light-blue-theme',
      isDefault: false,
      primaryColor500: '#007791'
    },
    {
      id: 2,
      displayName: 'Dark-blue',
      name: 'dark-blue-theme',
      isDefault: false,
      primaryColor500: '#3f51b5'
    }, {
      id: 3,
      displayName: 'Lime',
      name: 'lime-theme',
      primaryColor500: 'rgb(205, 220, 57)'
    }, {
      id: 4,
      displayName: 'Purple',
      name: 'purple-theme',
      primaryColor500: 'rgb(103, 58, 183)'
    }, {
      id: 5,
      displayName: 'Yellow',
      name: 'yellow-theme',
      primaryColor500: '#fcca4b'
    }, {
      id: 6,
      displayName: 'custom-expo-yellow',
      name: 'custom-expo-yellow-theme',
      primaryColor500: '#fcca4b'
    },
    {
      id: 7,
      displayName: 'Brown',
      name: 'brown-theme',
      primaryColor500: '#c2a08a'
    },
  ];

  version = VERSION;
  theme: SiteTheme;
  themeChangerButton: string;

  public findThemeByName(name: string) {
      return this.themes.find(currentTheme => currentTheme.name.toLocaleLowerCase() === name.toLocaleLowerCase()) || this.themes.find(currentTheme => currentTheme.isDefault);
  }

  public selectTheme(themeName: string) {
    RequestUtils.logRequest('Start selecting a theme...', { themeName })
    const theme = this.themes.find(currentTheme => currentTheme.displayName.toLocaleLowerCase() === themeName.toLocaleLowerCase());
    RequestUtils.logRequest('Theme found', { theme })
    if (!theme) {
      return;
    }

    this.currentTheme = theme;

    if (theme.isDefault) {
      this.styleManager.removeStyle('theme');
    } else {
      this.styleManager.setStyle('theme', `${theme.name}.css`);
    }

    if (this.currentTheme) {
      // liveAnnouncer is for accessability, but unsure how it actually helps bcs docs are just terrible on this one.
      // Comment for now
      // this.liveAnnouncer.announce(`${theme.displayName} theme selected.`, 'polite', 3000);
      this.storeTheme(this.currentTheme);
    }
    RequestUtils.logRequest('End selecting a theme...')
  }

  static storageKey = 'theme-storage-current-name';

  onThemeUpdate: EventEmitter<SiteTheme> = new EventEmitter<SiteTheme>();

  storeTheme(theme: SiteTheme) {
    try {
      this.setValue = theme
    } catch { }

    this.onThemeUpdate.emit(theme);
  }

  getStoredThemeName(): Observable<SiteTheme> {
    try {
     return this.getValue
    } catch {
      return null;
    }
  }

  clearStorage() {
    try {
      this.remove();
    } catch { }
  }
}
