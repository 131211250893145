
export class RequestUtils {

    public static defaultRedirectIfSomethingWrong = `https://www.streamlinedhq.com`;

    /**
     * Simple logging function to format logs as JSON
     * @param {string} message Log message
     * @param {Object} [data] Additional data to log
     */
    public static logRequest(message: string, data: any = {}) {
        try {
            let logData = { timestamp: new Date().toISOString(), application: 'shop', message, ...data };
            if (JSON.stringify(logData).length > 1000) {
                logData.message = logData.message.substring(0, 1000);
                logData.data = logData.data.substring(0, 1000);
            }
            console.log(JSON.stringify(logData));
        } catch (error) {
            console.error('Error while logging request:', error);
        }
    };
}