<div class="image-text-box-over-dynamic-cmpt">
    <div class="image-text-box-over-dynamic-cmpt--image">
        <img onError="this.src='assets/img/img-not-available.png';" 
             [src]="singleItem?.media?.url" 
             [alt]="singleItem?.media?.altText ? singleItem?.media?.altText : ''"
             [title]="singleItem?.media?.title ? singleItem?.media?.title : ''" />
    </div>
    <div [style.background-color]="singleItem?.contentBackgroundColor" class="image-text-box-over-dynamic-cmpt--box">
        <h2>{{singleItem?.largeTitle}}</h2>
        <p>{{singleItem?.mediumTitle}}</p>
        <button class="border-button" (click)="navigateTo(singleItem?.url)">{{singleItem?.buttonText}}</button>
    </div>
</div>